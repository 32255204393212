import p1 from "../assets/productsAssets/p1.webp";
import p2 from "../assets/productsAssets/p2.webp";
import p3 from "../assets/productsAssets/p3.webp";
import p4 from "../assets/productsAssets/p4.png";
import p5 from "../assets/productsAssets/p5.webp";
import p6 from "../assets/productsAssets/p6.jpg";
import p7 from "../assets/productsAssets/p7.webp";
import p8 from "../assets/productsAssets/p8.jpg";
import p9 from "../assets/productsAssets/p9.jpg";
import p10 from "../assets/productsAssets/p10.webp";
import p11 from "../assets/productsAssets/p11.webp";
import p12 from "../assets/productsAssets/p12.webp";
import p13 from "../assets/productsAssets/p13.webp";
import p14 from "../assets/productsAssets/p14.webp";
import p15 from "../assets/productsAssets/p15.webp";
import p16 from "../assets/productsAssets/p16.avif";
import p17 from "../assets/productsAssets/p17.webp";
import p18 from "../assets/productsAssets/p18.webp";
import p19 from "../assets/productsAssets/p19.webp";
import p20 from "../assets/productsAssets/p20.avif";
import p21 from "../assets/productsAssets/p21.webp";
import p22 from "../assets/productsAssets/p22.jpg";
import p23 from "../assets/productsAssets/p23.webp";
import p24 from "../assets/productsAssets/p24.jpg";
import p25 from "../assets/productsAssets/p25.png";
import p26 from "../assets/productsAssets/p26.jpg";
import p27 from "../assets/productsAssets/p27.webp";
import p28 from "../assets/productsAssets/p28.webp";
import p29 from "../assets/productsAssets/p29.jpg";
import p30 from "../assets/productsAssets/p30.jpeg";
import p31 from "../assets/productsAssets/p31.jpg";
import p32 from "../assets/productsAssets/p32.png";
import p33 from "../assets/productsAssets/p33.jpg";
import p34 from "../assets/productsAssets/product34.png";
import p35 from "../assets/productsAssets/p35.jpeg";
import p36 from "../assets/productsAssets/p36.jpg";
import p37 from "../assets/productsAssets/p37.jpg";
import p38 from "../assets/productsAssets/p38.webp";
import p39 from "../assets/productsAssets/p39.webp";
import p40 from "../assets/productsAssets/p40.webp";
import p41 from "../assets/productsAssets/p41.webp";
import p42 from "../assets/productsAssets/p42.jpeg";
import p43 from "../assets/productsAssets/p43.webp";
import p45 from "../assets/productsAssets/p45.webp";
import p46 from "../assets/productsAssets/p46.webp";
import p47 from "../assets/productsAssets/p47.jpg";
import p48 from "../assets/productsAssets/p48.jpg";
import p49 from "../assets/productsAssets/p49.jpg";
import p50 from "../assets/productsAssets/p50.jpg";
import p51 from "../assets/productsAssets/p51.jpg";
import p52 from "../assets/productsAssets/p52.png";
import p53 from "../assets/productsAssets/p53.jpg";
import p54 from "../assets/productsAssets/p54.webp";
import p55 from "../assets/productsAssets/p55.webp";
import p56 from "../assets/productsAssets/p56.webp";
import p57 from "../assets/productsAssets/p57.webp";
import p58 from "../assets/productsAssets/p58.avif";
import p59 from "../assets/productsAssets/p59.webp";
import p61 from "../assets/productsAssets/p61.png";
import p63 from "../assets/productsAssets/p63.jpg";
import p64 from "../assets/productsAssets/p64.jpg";
import p65 from "../assets/productsAssets/p65.webp";
import p66 from "../assets/productsAssets/p66.webp";
import p67 from "../assets/productsAssets/p67.jpg";
import p68 from "../assets/productsAssets/p68.jpg";
import p69 from "../assets/productsAssets/p69.jpg";
import p70 from "../assets/productsAssets/p70.jpg";
import p71 from "../assets/productsAssets/p71.webp";
import p72 from "../assets/productsAssets/p72.jpeg";
import p73 from "../assets/productsAssets/p73.jpg";
import p74 from "../assets/productsAssets/p74.jpeg";
import p75 from "../assets/productsAssets/p75.webp";
import p76 from "../assets/productsAssets/p76.jpg";
import p77 from "../assets/productsAssets/p77.webp";
import p79 from "../assets/productsAssets/p79.jpeg";
import p80 from "../assets/productsAssets/p80.jpeg";
import p81 from "../assets/productsAssets/p81.jpeg";
import p82 from "../assets/productsAssets/p82.png";
import p83 from "../assets/productsAssets/p83.webp";
import p84 from "../assets/productsAssets/p84.jpg";
import p85 from "../assets/productsAssets/p85.jpg";
import p86 from "../assets/productsAssets/p86.jpg";
import p87 from "../assets/productsAssets/p87.jpeg";
import p88 from "../assets/productsAssets/p88.jpeg";
import p89 from "../assets/productsAssets/p89.jpg";
import p90 from "../assets/productsAssets/p90.webp";
import p91 from "../assets/productsAssets/p91.jpeg";
import p92 from "../assets/productsAssets/p92.jpeg";
import p93 from "../assets/productsAssets/p93.jpeg";
import p94 from "../assets/productsAssets/p94.jpeg";
import p95 from "../assets/productsAssets/p95.jpeg";
import p96 from "../assets/productsAssets/p96.jpg";
import p97 from "../assets/productsAssets/p97.jpeg";
import p98 from "../assets/productsAssets/p98.jpeg";
import p99 from "../assets/productsAssets/p99.jpg";
import p100 from "../assets/productsAssets/p100.jpeg";
import p101 from "../assets/productsAssets/p101.jpg";
import p102 from "../assets/productsAssets/p102.jpeg";
import p103 from "../assets/productsAssets/p103.jpeg";
import p104 from "../assets/productsAssets/p104.webp";
import p105 from "../assets/productsAssets/p105.avif";
import p106 from "../assets/productsAssets/p106.avif";
import p107 from "../assets/productsAssets/p107.jpeg";
import p108 from "../assets/productsAssets/p108.jpg";
import p109 from "../assets/productsAssets/p109.jpg";
import p110 from "../assets/productsAssets/p110.webp";
import p112 from "../assets/productsAssets/p112.webp";
import p113 from "../assets/productsAssets/p113.png";
import p114 from "../assets/productsAssets/p114.jpeg";
import p115 from "../assets/productsAssets/p115.png";
import p116 from "../assets/productsAssets/p116.webp";
import p117 from "../assets/productsAssets/p117.webp";
import p118 from "../assets/productsAssets/p118.jpg";
import p119 from "../assets/productsAssets/p119.webp";
import p120 from "../assets/productsAssets/p120.webp";
import p121 from "../assets/productsAssets/p121.jpg";
import p122 from "../assets/productsAssets/p122.webp";
import p123 from "../assets/productsAssets/p123.avif";
import p124 from "../assets/productsAssets/p124.webp";
import p125 from "../assets/productsAssets/p125.jpg";
import p127 from "../assets/productsAssets/p127.jpg";
import p128 from "../assets/productsAssets/p128.webp";
import p129 from "../assets/productsAssets/p129.jpeg";
import p130 from "../assets/productsAssets/p130.webp";
import p131 from "../assets/productsAssets/p131";
import p132 from "../assets/productsAssets/p132.jpg";
import p133 from "../assets/productsAssets/p133.jpg";
import p135 from "../assets/productsAssets/p135.jpg";
import p136 from "../assets/productsAssets/p136.jpg";
import p137 from "../assets/productsAssets/p137.jpg";
import p138 from "../assets/productsAssets/p138.jpg";
import p139 from "../assets/productsAssets/p139.jpg";
import p140 from "../assets/productsAssets/p140.jpg";
import p141 from "../assets/productsAssets/p141.jpg";
import p142 from "../assets/productsAssets/p142.webp";
import p143 from "../assets/productsAssets/p143.jpg";
import p144 from "../assets/productsAssets/p144.jpg";
import p145 from "../assets/productsAssets/p145.jpg";
import p146 from "../assets/productsAssets/p146.jpg";
import p147 from "../assets/productsAssets/p147.jpg";
import p148 from "../assets/productsAssets/p148.jpg";

const productsData = [
  {
    id: "product69",
    brand: "Jean Paul Gaultier",
    productTitle: "Scandal By Night",
    image: p69,
    price: 49.99,
    description:
      "This perfume, part of the Jean Paul Gaultier collection, is a rich blend of honey, tuberose, and sandalwood. It's an intense and captivating scent, perfect for nights filled with scandalous adventures.",
    category: "Parfume",
  },
  // {
  //   id: "product700",
  //   brand: "Jean Paul Gaultier",
  //   productTitle: "Scandal By Night",
  //   image: p69,
  //   price: 1,
  //   description:
  //     "This perfume, part of the Jean Paul Gaultier collection, is a rich blend of honey, tuberose, and sandalwood. It's an intense and captivating scent, perfect for nights filled with scandalous adventures.",
  //   category: "Parfume",
  // },
  {
    id: "product2",
    brand: "CeraVe",
    productTitle: "Hydrating Facial Cleanser",
    size: 236,
    image: p2,
    price: 18.99,
    discountPrice: 14.99,
    description:
      "CeraVe Hydrating Facial Cleanser is a unique formula that cleanses, hydrates, and helps restore the skin's protective barrier with three essential ceramides (1, 3, 6-II). Fragrance-free, gentle, and non-irritating, suitable for normal to dry skin.",
    category: "Skincare",
  },
  {
    id: "product47",
    brand: "Sheglam",
    productTitle: "Primer Stick",
    image: p47,
    price: 11.99,
    description:
      "Sheglam Primer Stick - your makeup's best friend! This stick prepares your skin, creating a smooth canvas for perfect makeup application. Lightweight and easy to use, it blurs imperfections and enhances the longevity of your look. Elevate your makeup routine with Sheglam Primer Stick for a flawless finish.",
    category: "Make Up",
  },
  {
    id: "product3",
    brand: "Anastasia Beverly Hills",
    productTitle: "Modern Renaissance",
    image: p3,
    price: 19.99,
    description:
      "This palette from Anastasia Beverly Hills is a mesmerizing palette of 14 romantic shades. From soft mattes to rich shimmers, its blendable and long-lasting formula turns every day into a canvas for your beauty. Elevate your look effortlessly with this must-have!",
    category: "Make Up",
  },
  {
    id: "product11",
    brand: "YSL",
    productTitle: "Libre",
    image: p11,
    size: 50,
    price: 49.99,
    description:
      "Eau de Parfum, the iconic refillable feminine perfume now from Yves Saint Laurent. Freedom to live everything in excess. The perfume of a strong, bold, and free woman who experiences her extraordinary freedom. Lavender first, with flowers.",
    category: "Parfume",
  },
  {
    id: "product71",
    brand: "The Ordinary",
    productTitle: "Face Serum",
    image: p71,
    price: 14.99,
    description:
      "A key element for radiant skin care! This serum, known for its simplicity and effectiveness, targets various skin concerns. With active ingredients such as hyaluronic acid or niacinamide, it hydrates and rejuvenates.",
    category: "Skincare",
  },
  {
    id: "product132",
    brand: "Huda Beauty",
    productTitle: "Easy Bake and Snatch pressed Brightening and Setting Powder",
    image: p132,
    price: 39.99,
    shades: "Cupcake",
    description:
      "Easy Bake and Snatch Pressed Brightening and Setting Powder effortlessly perfects your makeup with a silky, lightweight formula. Available in versatile shades, it sets makeup for a flawless finish while brightening the complexion. Achieve a seamless, long-lasting look with this essential beauty tool.",
    category: "Make Up",
  },
  {
    id: "product129",
    brand: "Tom Ford",
    productTitle: "Eye Color Quad Eyeshadow Palette",
    image: p129,
    shades: "Body Heat",
    price: 59.99,
    description:
      "Tom Ford Eye Color Quad Eyeshadow Palette offers luxurious versatility, featuring four harmonious shades for endless eye looks. With rich pigmentation and smooth textures, each palette delivers effortless blending and long-lasting wear, elevating your makeup game with sophistication and style.",
    category: "Make Up",
  },
  {
    id: "product4",
    brand: "Bio-Oil",
    productTitle: "Skincare Oil",
    size: 125,
    image: p4,
    price: 17.99,
    description:
      "Bio-Oil Skincare Oil - your secret to radiant skin! This multi-purpose oil is a blend of nourishing ingredients, treating scars, stretch marks, and dryness. It absorbs quickly, leaving your skin soft and glowing. Embrace healthy and beautiful skin with Bio-Oil!",
    category: "Skincare",
  },
  {
    id: "product5",
    brand: "Pixie",
    productTitle: "Vitamin C Tonic",
    size: 125,
    image: p5,
    price: 14.99,
    description:
      "Pixi Beauty Vitamin-C Tonic is a daily facial tonic containing Vitamin C, a powerful antioxidant known for boosting skin radiance. This tonic helps achieve smooth and vibrant skin, while preparing your skin for the rest of your skincare routine.",
    category: "Skincare",
  },
  {
    id: "product135",
    brand: "Momento ",
    productTitle: "Duvet Days eau de toilette ",
    // size: 125,
    image: p135,
    price: 5.99,
    description:
      "Duvet Days by Boots is a Floral fragrance for women. Duvet Days was launched in 2020. Top notes are Bergamot and Lemon; middle notes are Violet and Jasmine; base note is White Musk.",
    category: "Perfumes",
  },
  {
    id: "product136",
    brand: "Sanctuary Spa",
    productTitle: "Petite Retreat Gift Set",
    // size: 125,
    image: p136,
    price: 27.99,
    description:
      "A treasure trove of must-have miniatures, indulge body & mind with this collection of signature heroes. Fragranced with our iconic fragrance with refreshing notes of jasmine, grapefruit & vanilla, give the gift of self-care.",
    category: "Skincare",
  },
  {
    id: "product137",
    brand: "Boots",
    productTitle: "Wanderlust Minis Collection Beauty Box",
    // size: 125,
    image: p137,
    price: 64.99,
    description:
      "For just €65, you will receive our gorgeous 'Beauty is within' travel cork bag, full to the brim with brilliant beauty besties to set you up nicely for your holidays.  ",
    category: "Skincare",
  },
  {
    id: "product138",
    brand: "Pure & CO",
    productTitle: "Discovery Giftset - Roll on eau de toilette 5x 10ml",
    // size: 125,
    image: p138,
    price: 29.99,
    description:
      "Fruity and floral, unexpected yet reassuring. This demenine scent mixes punchy sweet orange and invigorating peony with touches of bitter-sweet cherry blossom. Apricot contrasts the balsamic cedarwood and plum - to make a sweet but not sugary fragrance. ",
    category: "Perfumes",
  },
  {
    id: "product139",
    brand: "Sanctuary Spa",
    productTitle: "Little Moments Gift Set",
    // size: 125,
    image: p139,
    price: 24.99,
    description:
      "The ultimate gift for fans of floral fragrances, this gift set contains heroes from our luscious Lily & Rose collection. Cleanse, polish & moisturise skin and leave it beautifully fragranced with the blossoming notes of damask rose, white lily & palmarosa.",
    category: "Perfumes",
  },
  {
    id: "product140",
    brand: "Sanctuary Spa",
    productTitle: "Wellness Pillow Pack Gift Set",
    // size: 125,
    image: p140,
    price: 27.99,
    description:
      "Create the ultimate self-care escape with this curated trio of wellness heroes, housed in a pillow shaped box. Fragranced with our patented wellness fragrance that is scientifically proven to help you fall asleep faster & for longer, inhale the heavenly blend of violet, jasmine and sandalwood to drift off in a dreamy nights sleep.",
    category: "Skincare",
  },
  {
    id: "product141",
    brand: "Ted Baker",
    productTitle: "Body Spray Collection",
    // size: 125,
    image: p141,
    price: 25.99,
    description:
      "​Tailor-made for leaving lasting impressions, Ted Baker's new fragrances are designed to be worn alone or layered to create your own signature scent. ",
    category: "Skincare",
  },
  {
    id: "product142",
    brand: "Impulse",
    productTitle: "The Glow Getter Gift Set",
    // size: 125,
    image: p142,
    price: 19.99,
    description:
      "Impulse Shimmer & Glow Mist Beauty Bag Gift Set features the perfect Christmas gifts for her: Impulse Quartz Shimmer Fragrance tucked in a holographic shell-shaped cosmetic bag",
    category: "Skincare",
  },
  {
    id: "product143",
    brand: "Momento",
    productTitle: " Soaring Escape eau de toilette 10ml",
    // size: 125,
    image: p143,
    price: 5.99,
    description:
      "Flash back to the exhilarating days of freedom and joy: Bursting with tangy blackberry, fruity raspberry, flowery muguet and aromatic jasmine, it’s a combination of floral amber scents designed to feel like an exciting liberation. It’s time to escape.",
    category: "Perfumes",
  },
  {
    id: "product145",
    brand: "Skinny Dip",
    productTitle: "Dip Ice Cream Lip Balm Trio",
    // size: 125,
    image: p145,
    price: 19.99,
    description:
      "Keep your lips happy and hydrated with this dreamy trio of ice cream-scented lip balms! Complete with a sprinkle-covered mirror so you can do touch-ups when needed.",
    category: "Make Up",
  },
  {
    id: "product144",
    brand: "Burt's Bees",
    productTitle: " Essential Kit",
    // size: 125,
    image: p144,
    price: 34.99,
    description:
      "Give your loved one’s daily-use products a nourishing boost with this collection of natural essentials. Including 5 travel-size products to gently remove makeup and dirt, moisturize and smooth dry skin, lips, and hands, this self-care set is a go-to gift for birthdays, holidays and Mother’s Day.",
    category: "Skincare",
  },
  {
    id: "product146",
    brand: "Sanctuary Spa",
    productTitle: "Signature Minis Gift Set",
    // size: 125,
    image: p146,
    price: 24.99,
    description:
      "Elevate your self-care routine with this curated collection of bath & body treats. Sink into pure relaxation with our indulgent bath float then wash, scrub & moisturise in our iconic floriental fragrance with refreshing notes of jasmine, grapefruit & vanilla",
    category: "Skincare",
  },
  {
    id: "product147",
    brand: "Umberto Giannini",
    productTitle: "Umberto Giannini Scalp Restore Starter Kit",
    // size: 125,
    image: p147,
    price: 19.99,
    description:
      "Our bestselling scalp wash-day favourites now in a 50ml x 3 travel starter kit. Get to the root of the problem and say goodbye to dry, itchy scalps. Cleans, detoxifies and soothes whilst sloughing away dead skin and sebum to encourage scalp health and growth of strong, shiny, healthy hair.",
    category: "Skincare",
  },
  {
    id: "product148",
    brand: "Plumping Lip Oil",
    productTitle: "Plumping Lip Oil, Hydrating Lip Gloss Tinted Lip Balm",
    // size: 125,
    image: p148,
    price: 12.99,
    description:
      " Shining glow from every angle! From daily to your special days, anywhere you go, this lip gloss can make your day perfect.",
    category: "Make Up",
  },
  {
    id: "product16",
    brand: "MAC",
    productTitle: "Powder Kiss Lipstick",
    image: p16,
    price: 19.99,
    unavailable: "Our of Stock",
    shades: "Sultry Move",
    description:
      "A spectrum of shades in creamy and long-lasting formulas. From everyday elegance to bold drama, find the perfect match for your lips. Boost your confidence and express yourself with MAC - where every shade tells a story.",
    category: "Make Up",
  },
  {
    id: "product131",
    brand: "Kiko",
    productTitle: "Green Me Volume Mascasa",
    image: p131,
    shades: "Pure Black",
    price: 15.99,
    description:
      "Kiko Green Me Volume Mascara delivers lush, voluminous lashes with a touch of nature. Infused with 99% natural-origin ingredients, its creamy formula nourishes while enhancing volume and definition. The eco-friendly packaging reflects Kiko's commitment to sustainability and beauty innovation.",
    category: "Make Up",
  },
  {
    id: "product105",
    brand: "MAC",
    productTitle: "Powder Kiss Lipstick",
    image: p105,
    price: 19.99,
    unavailable: "Our of Stock",
    shades: "Devoted to chill",
    description:
      "A spectrum of shades in creamy and long-lasting formulas. From everyday elegance to bold drama, find the perfect match for your lips. Boost your confidence and express yourself with MAC - where every shade tells a story.",
    category: "Make Up",
  },
  {
    id: "product6",
    brand: "Pixie",
    productTitle: "Retinol Tonic",
    size: 125,
    image: p6,
    price: 14.99,
    description:
      "This powerful youth-preserving tonic contains a unique Retinol that helps renew the skin for a natural glow. Antioxidants and potent plant extracts replenish the skin for a smoother and softer appearance, soothing sensitive skin.",
    category: "Skincare",
  },

  {
    id: "product32",
    brand: "Kylie",
    productTitle: "Lipstick & Lipliner",
    image: p32,
    price: 15.99,
    description:
      "Kylie Lipstick & Lipliner - a dynamic duo for irresistible lips! This set combines a creamy lipstick with a matching lip liner for precise definition. Achieve long-lasting and captivating lips with Kylie's distinctive style and quality. Your key to bold beauty!",
    category: "Make Up",
  },
  {
    id: "product1",
    brand: "Prada",
    productTitle: "Paradoxe Intense - Tester",
    size: 90,
    image: p1,
    price: 49.99,
    description:
      "This is a fragrance with floral, quince aroma that refreshes with the extraction of neroli blossoms to capture the fresh aroma of flowers, sensuality with the converted quince to reveal a lively warmth and intensity through a revolutionary musk molecule for an intense yet delicate trace.",
    category: "Parfume",
  },
  {
    id: "product19",
    brand: "Too Faced",
    productTitle: "Cloud Crush Blush",
    image: p17,
    price: 27.99,
    shades: "Watermelon Rain",
    unavailable: "Our of Stock",
    description:
      "A fruit blush for your cheeks! This blush gives a natural, radiant glow with a sweet shimmering hue. Silky, blendable, and long-lasting, it's the perfect color for a fresh and lively look.",
    category: "Make Up",
  },
  {
    id: "product70",
    brand: "Maison Francis Kurkdijan",
    productTitle: "Baccarat Rouge 540",
    image: p70,
    price: 49.99,
    description:
      "Baccarat Rouge 540 - a masterpiece of the art of perfumery! This fragrance from Maison Francis Kurkdjian is a symphony of quince, saffron, and cedarwood. Its unique and luxurious aroma leaves an indelible impression, evoking sophistication and allure.",
    category: "Parfume",
  },
  {
    id: "product7",
    brand: "The ordinary",
    productTitle: "Glyconil Acid",
    image: p7,
    unavailable: "Our of Stock",
    price: 14.99,
    description:
      "An exfoliating tonic with 7% glycolic acid. It's a gentle exfoliator that softens the skin texture and promotes a more even skin tone, as well as a brighter complexion with regular use. It also reduces the appearance of spots and wrinkles on the skin. The toner format is water-based.",
    category: "Skincare",
  },
  {
    id: "product8",
    brand: "Vichy",
    productTitle: "Normadem Phytosolution",
    image: p8,
    price: 14.99,
    description:
      "A daily gel cleanser that cleanses and revitalizes oily and blemish-prone skin's appearance. It perfectly removes impurities, including dirt, dust, and pollutants, leaving the skin feeling clean and looking fresher, cleaner, and mattified. In just one month, black spots appear less pronounced, and pores appear less visible.",
    category: "Skincare",
  },
  {
    id: "product9",
    brand: "La Roche-Posay",
    productTitle: "Hydrating Gentle Cleanser",
    size: 400,
    image: p9,
    price: 24.99,
    description:
      "Toleriane Hydrating Gentle Cleanser is a daily facial cleanser for normal to dry and sensitive skin. Formulated with La Roche-Posay prebiotic thermal water, niacinamide, and ceramide-3, this facial cleanser gently cleanses the skin of impurities, makeup, and pollutants while maintaining the skin's natural moisture barrier and pH.",
    category: "Skincare",
  },
  {
    id: "product127",
    brand: "Giorgio Armani",
    productTitle: "My Way",
    size: 100,
    image: p127,
    price: 69.99,
    description:
      "My Way by Giorgio Armani is a Floral fragrance for women. This is a new fragrance. My Way was launched in 2020. My Way was created by Carlos Benaim and Bruno Jovanovic. Top notes are Orange Blossom and Bergamot; middle notes are Tuberose and Indian Jasmine; base notes are Madagascar Vanilla, White Musk, and Virginian Cedar.",
    category: "Parfume",
  },
  {
    id: "product10",
    brand: "CeraVe",
    productTitle: "Reparative Hand Cream",
    image: p10,
    size: 50,
    unavailable: "Our of Stock",
    price: 11.99,
    description:
      "This is a non-greasy hand cream that absorbs quickly and deeply moisturizes rough and dry skin. The active ingredients of the reparative hand cream include hyaluronic acid and three essential ceramides to hydrate the skin.",
    category: "Skincare",
  },
  {
    id: "product12",
    brand: "Rare Beauty",
    productTitle: "Positive Light Liquid Luminizer Mesmerize",
    image: p12,
    shades: "Mesmerize",
    unavailable: "Our of Stock",
    price: 23.99,
    description:
      "This weightless formula gives you a brilliant glow. Easy to blend, it enhances your features for a natural or bold glow. Elevate your look effortlessly with Rare Beauty - because you deserve to shine!",
    category: "Make Up",
  },
  {
    id: "product14",
    brand: "La Roche-Posay",
    productTitle: "Pure Vitamin c10",
    image: p13,
    price: 29.99,
    unavailable: "Our of Stock",
    description:
      "La Roche-Posay Pure Vitamin C10 - your daily skin booster! This powerful serum with 10% pure vitamin C brightens and softens. Lightweight and quickly absorbed, it revitalizes your skin, leaving you radiant and healthy-looking.",
    category: "Skincare",
  },
  {
    id: "product15",
    brand: "La Roche-Posay",
    productTitle: "Retinol Serum B3",
    image: p14,
    price: 29.99,
    unavailable: "Our of Stock",
    description:
      "This serum, enriched with pure retinol and vitamin B3, fights wrinkles and improves skin tone. Lightweight and non-greasy, it's the key to smoother, younger-looking skin.",
    category: "Skincare",
  },
  {
    id: "product13",
    brand: "Bobbi Brown",
    productTitle: "Long-Wear Cream Eyeshadow Stick",
    image: p15,
    shades: "Dusty Mauve",
    price: 13.99,
    unavailable: "Our of Stock",
    description:
      "This convenient pencil provides light, blendable color with a single stroke. Perfect for a quick and mesmerizing eye look anytime, anywhere. Elevate your gaze with Bobbi Brown - because beauty should be effortless!",
    category: "Make Up",
  },

  {
    id: "product17",
    brand: "Vichy",
    productTitle: "Normaderm Phytosolution",
    image: p19,
    price: 14.99,
    description:
      "Vichy Normaderm Phytosolution - your solution for clean skin! This daily cream combines natural ingredients to fight imperfections. Lightweight and non-greasy, it hydrates and helps control excess oil. Discover your best skin with Vichy's expert care.",
    category: "Skincare",
  },
  {
    id: "product18",
    brand: "La Roche Posay",
    productTitle: "Anthelios",
    image: p18,
    unavailable: "Our of Stock",
    price: 17.99,
    description:
      "La Roche-Posay Anthelios - your perfect sun protection! This sunscreen provides broad-spectrum protection against UV rays. Lightweight, oil-free formula, suitable for sensitive skin. Protect yourself carefully by using Anthelios from La Roche-Posay.",
    category: "Skincare",
  },
  {
    id: "product20",
    brand: "CeraVe",
    productTitle: "Foaming Cleanser",
    image: p20,
    price: 14.99,
    description:
      "CeraVe Foaming Cleanser - your daily dose of freshness! This gentle cleanser removes impurities and oil without disrupting your skin's natural barrier. With a foaming formula, it leaves your skin clean, fresh, and ready to face the day. Experience clean contact with CeraVe!",
    category: "Skincare",
  },
  {
    id: "product21",
    brand: "CeraVe",
    productTitle: "Acne Foaming Cream Cleanser",
    image: p21,
    price: 11.99,
    description:
      "CeraVe Acne Foaming Cream Cleanser – your solution for clearer skin! This gentle cleanser removes dirt, oil, and acne-causing bacteria without disrupting the skin's natural barrier. With benzoyl peroxide and ceramides, it treats and prevents breakouts while hydrating and soothing the skin",
    category: "Skincare",
  },
  {
    id: "product22",
    brand: "CeraVe",
    productTitle: "SA Cleanser",
    image: p22,
    price: 14.99,
    description:
      "CeraVe SA Cleanser - cleanse, renew, and refresh! Formulated with salicylic acid and ceramides, this cleanser gently exfoliates, unclogs pores, and hydrates. Your daily solution for smoother and softer skin. Embrace renewed radiance with CeraVe!",
    category: "Skincare",
  },
  {
    id: "product23",
    brand: "CeraVe",
    productTitle: "Moisturising Lotion",
    image: p23,
    price: 14.99,
    description:
      "CeraVe Moisturising Lotion - hydration at its best! This lightweight, non-greasy formula with essential ceramides and hyaluronic acid replenishes and retains moisture. Perfect for daily use, leaving your skin soft, smooth, and nourished.",
    category: "Skincare",
  },
  {
    id: "product24",
    brand: "CeraVe",
    productTitle: "Detergente Idratante",
    image: p24,
    price: 14.99,
    description:
      "CeraVe Detergente Idratante - top hydration! This lightweight, non-greasy formula with essential ceramides and hyaluronic acid restores and retains moisture. Perfect for daily use, leaving your skin soft, smooth, and nourished. Embrace hydration with CeraVe!",
    category: "Skincare",
  },
  {
    id: "product25",
    brand: "Dior",
    productTitle: "Capture totale",
    image: p25,
    price: 25.99,
    unavailable: "Our of Stock",
    description:
      "Dior Capture Totale - your skin's rejuvenation! This luxurious skincare range is refreshed with advanced formulas. From the iconic serum to the multi-perfection cream, experience radiant transformation. Embrace timeless beauty with Dior Capture Totale.",
    category: "Skincare",
  },
  {
    id: "product26",
    brand: "Dyson",
    productTitle: "Airwrap",
    image: p26,
    price: 489.99,
    description:
      "Dyson Airwrap - your hair's new best friend! This innovative styling tool uses air to curl, wave, smooth, and volumize without extreme heat. Effortlessly achieve salon-worthy hair at home with Dyson's game-changing Airwrap.",
    category: "Dyson",
  },
  {
    id: "product27",
    brand: "Charlotte Tilury",
    productTitle: "Mini Sitting Powder",
    image: p27,
    unavailable: "Our of Stock",
    price: 19.99,
    description:
      "Charlotte Tilbury Mini Setting Powder - your beauty secret on the go! This travel-sized setting powder locks in makeup for a flawless finish. Gentle and translucent, it keeps you looking fresh and radiant all day long.",
    category: "Make Up",
  },
  {
    id: "product28",
    brand: "Fenty Beauty",
    productTitle: "Countor Stick",
    image: p28,
    price: 15.99,
    unavailable: true,
    description:
      "Fenty Beauty Contour Stick - sculpt and define with ease! This creamy contour stick blends effortlessly for a natural and sculpted look. Available in versatile shades, it's the key to perfect contouring. Elevate your beauty game with Fenty Beauty's contour stick.",
    category: "Make Up",
  },
  {
    id: "product29",
    brand: "Kiko",
    productTitle: "3D Hydra Lipgloss 10",
    image: p29,
    shades: "10 Sparkling Strawberry",
    price: 15.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine for your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy. Elevate your lip game with Kiko's 3D Hydra Lipgloss for irresistible lushness.",
    category: "Make Up",
  },
  {
    id: "product30",
    brand: "Kiko",
    productTitle: "Matte Lipstick",
    image: p30,
    price: 15.99,
    description:
      "This lipstick glides on smoothly, providing a rich and matte finish. The long-lasting formula keeps your lips bold and beautiful. Enhance your look with Kiko's matte lipstick for a touch of timeless elegance.",
    category: "Make Up",
  },
  {
    id: "product31",
    brand: "Kiko",
    productTitle: "Lipliner",
    image: p31,
    unavailable: "Not in Stock",
    price: 15.99,
    description:
      "Kiko Lipliner – precision meets perfection! This lip liner defines and enhances your lips with ease. With a creamy texture and long-lasting formula, it keeps lip color in check throughout the day. Boost your lip game with Kiko's versatile and precise lipliner.",
    category: "Make Up",
  },
  {
    id: "product84",
    brand: "Kiko",
    productTitle: "3D Hydra Lipgloss 12",
    image: p84,
    shades: "12 Perly Amaryllis Red",
    price: 15.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine for your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy. Elevate your lip game with Kiko's 3D Hydra Lipgloss for irresistible lushness.",
    category: "Make Up",
  },
  {
    id: "product33",
    brand: "Rare Beauty",
    productTitle: "Corrector",
    image: p33,
    price: 15.99,
    description:
      "This lightweight corrector conceals effortlessly and brightens, effortlessly correcting imperfections. The buildable formula blends seamlessly, leaving you with a natural and radiant finish. Embrace your beauty with Rare Beauty Corrector.",
    category: "Make Up",
  },
  {
    id: "product34",
    brand: "Rare Beauty",
    productTitle: "Foundation",
    image: p34,
    unavailable: "Not in Stock",
    price: 15.99,
    description:
      "This weightless foundation blends effortlessly, providing buildable coverage for a natural and radiant look. Available in different shades, it enhances your unique beauty. Enhance your color with Rare Beauty – because your skin deserves to shine!",
    category: "Make Up",
  },
  {
    id: "product35",
    brand: "Charlotte Tilbury",
    productTitle: "Highlighter ",
    image: p35,
    price: 15.99,
    description:
      "This luminous highlighter creates a radiant and dewy finish. The finely milled formula glides on for a delicate or intense glow. Illuminate your features with Charlotte Tilbury's iconic highlighter for a heavenly beauty touch.",
    category: "Make Up",
  },
  {
    id: "product36",
    brand: "Estée Lauder",
    productTitle: "Lipstick",
    image: p36,
    price: 21.99,
    shades: "812 Change the World",
    description:
      "Estée Lauder Lipstick – elegance in every shade! This luxurious lipstick offers rich, long-lasting color with a creamy texture. From vibrant reds to delicate nudes, find the perfect shade to complement your style. Embrace timeless beauty with Estée Lauder lipstick.",
    category: "Make Up",
  },
  {
    id: "product37",
    brand: "Tom Ford",
    productTitle: "Ultrashine Lip Color",
    image: p37,
    shades: "27",
    price: 21.99,
    description:
      "This iconic lipstick delivers intense color and a creamy, lustrous texture. With a range of timeless shades, it elevates your lip game to unparalleled sophistication. Experience the embodiment of elegance with Tom Ford lipstick.",
    category: "Make Up",
  },
  {
    id: "product38",
    brand: "Burberry",
    productTitle: "Goddess",
    image: p38,
    price: 49.99,
    description:
      "A unique, gourmand scent led by a trio of vanillas and enriched with luminous lavender. The vanilla is collected by hand, then the beans spend five to seven weeks drying in the sun, before being stored in wooden boxes to mature and develop their sweet, signature aroma.",
    category: "Parfume",
  },
  {
    id: "product39",
    brand: "Gucci",
    productTitle: "Guilty",
    image: p39,
    price: 49.99,
    description:
      "This enchanting fragrance with a blend of floral and oriental notes creates a modern and daring scent. Embrace your confidence and indulge in the luxurious allure of Gucci Guilty for Women.",
    category: "Parfume",
  },
  {
    id: "product130",
    brand: "Huda Beauty",
    productTitle: "FauxFilterr Luminous Matte Concealer",
    image: p130,
    price: 27.99,
    shades: "Whiped Cream",
    description:
      "Huda Beauty's FauxFilterr Luminous Matte Concealer offers flawless coverage with a luminous matte finish. Its lightweight formula conceals imperfections while brightening the under-eye area. Long-wearing and blendable, it ensures a smooth, radiant complexion for all-day confidence.",
    category: "Make Up",
  },
  {
    id: "product40",
    brand: "Armani",
    productTitle: "Prive Ikat Bleu",
    image: p40,
    price: 59.99,
    description:
      "Armani Privé Ikat Bleu – a captivating masterpiece! This fragrance blends rare and delicate notes, creating a mesmerizing and sophisticated scent. Immerse yourself in the luxurious atmosphere of Armani Privé Ikat Bleu for an unparalleled olfactory experience.",
    category: "Parfume",
  },
  {
    id: "product41",
    brand: "OG",
    productTitle: "Concealer",
    image: p41,
    price: 6.99,
    description:
      "This concealer effortlessly hides imperfections, brightens, and provides a natural finish. With a lightweight formula, it blends seamlessly for a radiant complexion. Elevate your look with OG Concealer and reveal your natural beauty.      ",
    category: "Make Up",
  },
  {
    id: "product42",
    brand: "Kiko",
    productTitle: "Eyeliner Pencil",
    image: p42,
    price: 19.99,
    description:
      "Kiko Eyeliner Pencil – your precise tool for mesmerizing eyes! This pencil glides on smoothly, defining and enhancing your eyes effortlessly. With a long-lasting formula and a range of shades, achieve the perfect look with Kiko's eyeliner pencil – where simplicity meets precision.",
    category: "Make Up",
  },
  {
    id: "product43",
    brand: "Huda Beauty",
    productTitle: "Setting Powder",
    image: p43,
    price: 39.99,
    description:
      "Huda Beauty Setting Powder – your ultimate touch for a flawless look! This finely milled powder sets makeup, blurs imperfections, and leaves a velvety finish. Lightweight and long-lasting, it ensures your makeup stays perfect throughout the day. Elevate your beauty routine with Huda Beauty Setting Powder.",
    category: "Make Up",
  },
  {
    id: "product45",
    brand: "Sheglam",
    productTitle: "Eyebrow Gel",
    image: p45,
    unavailable: "Not in Stock",
    price: 19.99,
    description:
      "This effortless brow gel defines and sets eyebrows for a polished look. The lightweight formula keeps brows in place all day, enhancing your natural beauty with ease. Elevate your brow game with Sheglam Eyebrow Gel for a perfect finish.",
    category: "Make Up",
  },
  {
    id: "product46",
    brand: "Sheglam",
    productTitle: "Lip Scrub Balm",
    image: p46,
    price: 11.99,
    description:
      "Sheglam Lip Scrub Balm – your secret to luscious lips! This scrub balm exfoliates and nourishes, leaving your lips soft and smooth. With a balm-like texture, it's a perfect prelude to the perfect lip color. Pamper your lips with Sheglam for naturally irresistible allure.",
    category: "Make Up",
  },

  {
    id: "product48",
    brand: "Sheglam",
    productTitle: "Blush Stick",
    image: p48,
    price: 11.99,
    description:
      "Sheglam Blush Stick – your instant pop of color! This creamy blush stick adds a natural and dense glow to your cheeks. Easy to apply and blend, it enhances your color for a fresh and youthful look. Elevate your makeup routine with Sheglam Blush Stick for a rosy glow.",
    category: "Make Up",
  },
  {
    id: "product49",
    brand: "Sheglam",
    productTitle: "Primer",
    image: p49,
    unavailable: true,
    price: 19.99,
    description:
      "Sheglam Primer – your secret to a flawless base! This lightweight primer smooths, blurs, and preps your skin for makeup perfection. With a silky texture, it minimizes pores and extends the wear of your favorite products.",
    category: "Make Up",
  },
  {
    id: "product50",
    brand: "Sheglam",
    productTitle: "Face Cleanser",
    image: p50,
    price: 19.99,
    description:
      "This cleanser gently removes impurities, leaving your face clean and refreshed. With a gentle formula, it nourishes your skin, promoting a healthy glow. Elevate your skincare routine with Sheglam Face Cleanser for a fresh and radiant complexion.",
    category: "Make Up",
  },
  {
    id: "product51",
    brand: "Bella Beauty",
    productTitle: "Palette",
    image: p51,
    unavailable: "Not in Stock",
    price: 19.99,
    description:
      "Bella Beauty Palette – your all-in-one beauty essential! This comprehensive palette features a mesmerizing array of eyeshadows, blushes, and highlighters. With pigmented shades and a blendable formula, create endless looks for any occasion. Elevate your makeup collection with Bella Beauty Palette – because every look deserves perfection.",
    category: "Make Up",
  },
  {
    id: "product52",
    brand: "MAC",
    productTitle: "Eyeshadow",
    unavailable: true,
    image: p52,
    price: 19.99,
    description:
      "This iconic eyeshadow offers a wide range of shades and pigmented finishes. From velvety mattes to sparkling metallics, create endless looks with MAC's rich and blendable formula. Elevate your gaze with MAC eyeshadow for show-stopping eyes.",
    category: "Make Up",
  },
  {
    id: "product53",
    brand: "Estēe Lauder",
    productTitle: "Bronze",
    image: p53,
    price: 23.99,
    description:
      "Estée Lauder Bronze – your perfect glow! This luxurious bronzer adds warmth and radiance to your skin. With a silky smooth texture and buildable formula, achieve a sun-kissed glow that enhances your natural beauty. Elevate your look with Estée Lauder Bronze for luminous and sunlit skin.",
    category: "Make Up",
  },
  {
    id: "product54",
    brand: "Catrice",
    productTitle: "HD Liquid Coverage Foundation 005",
    image: p54,
    shades: "005 Ivory Beige",
    price: 8.99,
    description:
      "This foundation offers buildable coverage with a lightweight, breathable formula. With a wide range of shades, it creates a natural finish that lasts all day. Elevate your beauty routine with Catrice foundation for a radiant and flawless look.",
    category: "Make Up",
  },
  {
    id: "product54",
    brand: "Catrice",
    productTitle: "HD Liquid Coverage Foundation 030",
    image: p112,
    shades: "030 Sand Beige",
    price: 8.99,
    description:
      "This foundation offers buildable coverage with a lightweight, breathable formula. With a wide range of shades, it creates a natural finish that lasts all day. Elevate your beauty routine with Catrice foundation for a radiant and flawless look.",
    category: "Make Up",
  },
  {
    id: "product55",
    brand: "Real Techniques",
    productTitle: "Eye&eyebrows Brush Set",
    image: p55,
    price: 11.99,
    description:
      "This set includes essential brushes for precise mascara and eyebrow application. With high-quality bristles, achieve professional results effortlessly. Elevate your eye game with Real Techniques for a perfect finish.",
    category: "Make Up",
  },
  {
    id: "product56",
    brand: "Armani Beauty",
    productTitle: "Eye Tint",
    image: p56,
    shades: "27 Sunset",
    price: 29.99,
    description:
      "Armani Beauty Eye Tint – your shortcut to mesmerizing eyes! This liquid eyeshadow delivers intense, buildable color with a weightless feel. The long-lasting formula effortlessly glides on for a high-impact, glossy finish. Elevate your eye game with Armani Beauty Eye Tint for a serene and captivating look.",
    category: "Make Up",
  },
  {
    id: "product57",
    brand: "B-Goldi",
    productTitle: "Bright Drops Drunk Elephant",
    image: p57,
    price: 29.99,
    description:
      "This lightweight serum provides intensive hydration with vitamin B5 and pineapple ceramide. Perfect for all skin types, it replenishes and retains moisture, leaving your skin healthy and radiant. Embrace the dewy glow with B-Hydra.",
    category: "Make Up",
  },
  {
    id: "product58",
    brand: "Sephora",
    productTitle: "Glossed Lip Gloss",
    image: p58,
    size: 5,
    shades: "07 Lover",
    price: 14.99,
    description:
      "Sephora Lip Gloss – your ticket to perfection with shine! This lip gloss adds a gorgeous shine with a non-sticky formula. Choose from a range of shades for a smooth and luscious finish. Elevate your lip game with Sephora Lip Gloss for a glamorous and high-shine look.",
    category: "Make Up",
  },
  {
    id: "product59",
    brand: "Shein",
    productTitle: "Mini brush set",
    image: p59,
    price: 11.99,
    description:
      "Shein Lip Gloss – your ticket to perfection with shine! This lip gloss adds a gorgeous shine with a non-sticky formula. Choose from a range of shades for a smooth and luscious finish. Elevate your lip game with Shein Lip Gloss for a glamorous and high-shine look.",
    category: "Make Up",
  },
  {
    id: "product61",
    brand: "Kylie",
    productTitle: "Crème Liquid Lipstick Set Makeup Moment",
    image: p61,
    price: 14.99,
    description:
      "Set of Kylie Matte lipsticks – essential for bold and beautiful lips! This set features a collection of velvet matte lipsticks in various shades. With a smooth and long-lasting formula, achieve the perfect pout for any occasion.",
    category: "Make Up",
  },
  {
    id: "product63",
    brand: "Dior",
    productTitle: "Miss Dior",
    image: p63,
    price: 49.99,
    description:
      "This fragrance captures the essence of timeless femininity with fresh floral notes and a touch of allure. Experience the romantic essence of Miss Dior and embrace the captivating charm it brings.",
    category: "Parfume",
  },
  {
    id: "product64",
    brand: "Good Girl",
    productTitle: "Eau de Parfum",
    image: p64,
    price: 49.99,
    description:
      "Good Girl Eau de Parfum – a fragrance of daring contrasts! This scent from Carolina Herrera is a sweet and spicy blend, with notes of jasmine and tonka bean. Embrace your bold and mysterious side with Good Girl EDP. It's not just a fragrance; it's an attitude.",
    category: "Parfume",
  },
  {
    id: "product65",
    brand: "Dior",
    productTitle: "Hypnotic Poison",
    image: p65,
    price: 49.99,
    description:
      "Dior Hypnotic Poison – një eliksir magjepsës! Kjo aromë lëshon një rregull hipnotik me nota vanilje, bajame, dhe jasemin. Një aromë misterioze dhe përçueshme që lë një gjurmë të paharrueshme. Mërrini në magjinë e Dior Hypnotic Poison dhe lejoni magjinë të ndodhë.",
    category: "Parfume",
  },
  {
    id: "product66",
    brand: "Good Girl",
    productTitle: "Blush EDP",
    image: p66,
    price: 49.99,
    description:
      "Good Girl Eau de Parfum Blush – a feast of femininity's flowers! This fragrance from Carolina Herrera combines the sweetness of red berries with the sophistication of jasmine. With a playful yet elegant touch, it's a delightful expression of charm.",
    category: "Parfume",
  },
  {
    id: "product67",
    brand: "Dior",
    productTitle: "Sakura",
    image: p67,
    price: 49.99,
    description:
      "Sakura is the clear choice. A fragrance that celebrates the scent of Japanese cherry blossom that colors the landscape in a soft and delicate pink with the arrival of spring. A beauty as simple and magnificent as it gets.",
    category: "Parfume",
  },
  {
    id: "product68",
    brand: "Narciso Rodriguez",
    productTitle: "For her",
    image: p68,
    price: 49.99,
    description:
      "An iconic scent of everlasting sensuality! This fragrance intertwines floral, woody, and musky notes, creating a sophisticated and enchanting allure. With a blend of orange blossom, osmanthus, and musk, it's a modern classic that leaves a lasting impression.",
    category: "Parfume",
  },
  {
    id: "product72",
    brand: "Dior",
    productTitle: "Forever Skin Glow Foundation SPF 15",
    shades: "1.5",
    image: p72,
    price: 54.99,
    description:
      "Your secret to radiant and flawless skin! Provides a glowing finish with long-lasting coverage. Packed with skincare benefits and SPF protection, it enhances your natural radiance while providing sun protection.",
    category: "Make Up",
  },
  {
    id: "product73",
    brand: "Fenty Beauty",
    productTitle: "Pro Filt'r Soft Matte Longwear Foundation",
    shades: "175",
    image: p73,
    price: 39.99,
    description:
      "This foundation offers buildable coverage that lasts all day. With a soft matte formula, it blurs imperfections for a smooth and airy look.",
    category: "Make Up",
  },
  {
    id: "product74",
    brand: "Tom Ford",
    productTitle: "Eye Color Quad Eyeshadow Palette",
    shades: "Hazy Sensuality",
    image: p74,
    price: 69.99,
    description:
      "This quad contains four highly pigmented shades curated to create endless captivating combinations. With a velvety texture and long-lasting formula, it provides intense color payoff and effortless blending.",
    category: "Make Up",
  },
  {
    id: "product75",
    brand: "Fenty Beauty",
    productTitle: "Eaze Drop Blurring Skin Tint 03",
    shades: "03",
    image: p75,
    price: 25.99,
    description:
      "This light skin tint offers buildable coverage with a blurred effect, leaving your skin smooth and radiant. With a soft matte layer and easy application, it's perfect for daily wear.",
    category: "Make Up",
  },
  {
    id: "product76",
    brand: "Dior",
    productTitle: "Diorshow 5 Couleurs Couture Eyeshadow Palette",
    shades: "279 Denim - shades of denim blue and a gold hue",
    image: p76,
    price: 49.99,
    description:
      "This palette features five coordinated shades, curated to create endless looks. With a luxurious formula that blends seamlessly, it provides intense pigmentation and long-term wear.",
    category: "Make Up",
  },
  {
    id: "product77",
    brand: "Dior",
    productTitle: "Capture Dreamskin Moist & Perfect Cushion SPF 50",
    shades: "012 Porcelaine",
    image: p77,
    price: 59.99,
    description:
      "This cushion foundation provides moisture and perfects the skin with SPF 50 protection. With a lightweight formula and customizable coverage, it leaves the skin looking flawless and radiant.",
    category: "Make Up",
  },
  {
    id: "product78",
    brand: "Fenty Beauty",
    productTitle: "Eaze Drop Blurring Skin Tint 06",
    shades: "06",
    image: p75,
    price: 25.99,
    description:
      "This light skin tint offers buildable coverage with a blurred effect, leaving your skin smooth and radiant. With a soft matte layer and easy application, it's perfect for daily wear.",
    category: "Make Up",
  },
  {
    id: "product85",
    brand: "Kiko",
    productTitle: "3D Hydra Lipgloss 34",
    image: p85,
    shades: "34 Pearly Blood Orange 34",
    price: 15.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine of your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy. Elevate your lip game with Kiko's 3D Hydra Lipgloss for irresistible lushness.",
    category: "Make Up",
  },
  {
    id: "product79",
    brand: "The Rebel",
    productTitle: "Luxury Palette",
    shades: "The Rebel",
    image: p79,
    price: 39.99,
    description:
      "This mascara palette from Charlotte Tilbury contains four enchanting shades curated to create bold and rebellious looks. With rich pigmentation and a velvety texture, it's perfect for expressing your unique style.",
    category: "Make Up",
  },
  {
    id: "product80",
    brand: "Charlotte Tilbury",
    productTitle: "Beauty Light Wand",
    shades: "Pillow Talk Medium",
    image: p80,
    price: 19.99,
    description:
      "This highlighter from Charlotte Tilbury adds a soft and luminous glow to your skin. With a creamy formula and a medium shade, it enhances your natural beauty for a lit-from-within glow.",
    category: "Make Up",
  },
  {
    id: "product81",
    brand: "Charlotte Tilbury",
    productTitle: "Pillow Talk Original",
    shades: "Pillow Talk Original",
    image: p81,
    price: 19.99,
    description:
      "This magical wand is a blush and highlighter in one. It gives your cheeks a beautiful, soft pink glow that looks naturally radiant and fresh. Just one touch and you're ready to shine!",
    category: "Make Up",
  },
  {
    id: "product82",
    brand: "Tarte",
    productTitle: "Sculpt Tape Contour",
    shades: "Cool Bronze",
    image: p82,
    price: 13.99,
    description:
      "Tarte Shape Tape Contour is your shortcut to sculpted cheekbones! This easy-to-use cream blends like a dream, creating natural shadows for a defined and lifted look. Get the illusion of sharper features in seconds—it's like an instant face lift in a tube!",
    category: "Make Up",
  },
  {
    id: "product83",
    brand: "Kiko",
    productTitle: "3D Hydra Lipgloss 01",
    image: p83,
    shades: "01 Clear",
    price: 13.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine of your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy. Elevate your lip game with Kiko's 3D Hydra Lipgloss for irresistible lushness.",
    category: "Make Up",
  },
  {
    id: "product86",
    brand: "Kiko",
    productTitle: "3D Hydra Lipgloss 23",
    image: p86,
    shades: "23 Magenta",
    price: 13.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine of your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy.",
    category: "Make Up",
  },
  {
    id: "product87",
    brand: "Kiko",
    productTitle: "Lip Volume Tutu Rose",
    image: p87,
    shades: "Tutu Rose",
    price: 13.99,
    description:
      "Kiko 3D Hydra Lipgloss – the 3D shine of your lips! This hydrating lip gloss adds dimension and shine. The non-sticky formula keeps your lips hydrated and healthy. Elevate your lip game with Kiko's 3D Hydra Lipgloss for irresistible lushness.",
    category: "Make Up",
  },
  {
    id: "product88",
    brand: "Kiko",
    productTitle: "Lip Volume Tutu Transparent",
    image: p88,
    shades: "Transparent",
    price: 13.99,
    description:
      "Kiko Lip Volume plumps your lips for a fuller look! Hydrates with hyaluronic acid and leaves a delicate shine. Choose from the clear color or a pink touch for a natural confidence boost.",
    category: "Make Up",
  },
  {
    id: "product89",
    brand: "Kiko",
    productTitle: "Velvet Passion Matte Lipstick",
    image: p89,
    shades: "328 Rosy Brown",
    price: 15.99,
    description:
      "Get bold and beautiful lips with Kiko Velvet Passion matte lipstick! This creamy formula glides on effortlessly, delivering rich color with a luxurious matte finish. Feels comfortable and lasts all day, so you can look and feel amazing.",
    category: "Make Up",
  },
  {
    id: "product90",
    brand: "Kiko",
    productTitle: "Coloured Balm - Lip balm",
    image: p90,
    shades: "06 Blackberry",
    price: 8.99,
    description:
      "This balm combines a pure color tint with a hint of shine. Packed with vitamins A, E & C and shea butter, it keeps your lips nourished and feeling soft all day long. Choose from beautiful shades for a natural tint!",
    category: "Make Up",
  },
  {
    id: "product91",
    brand: "Kiko",
    productTitle: "Unlimited Double Touch 110",
    image: p91,
    shades: "110 Spicy Rose",
    price: 10.99,
    description:
      "Kiko Unlimited Double Touch: Two steps to bold and long-lasting color! This innovative lipstick combines a matte base with high-pigment color and a glossy top layer. Get intense, smear-proof color that lasts up to 12 hours with a marvelous shine.",
    category: "Make Up",
  },
  {
    id: "product92",
    brand: "Kiko",
    productTitle: "Unlimited Double Touch 114",
    image: p92,
    shades: "114 Orange Red",
    price: 10.99,
    description:
      "Kiko Unlimited Double Touch: Two steps to bold and long-lasting color! This innovative lipstick combines a matte base with high-pigment color and a glossy top layer. Get intense, smear-proof color that lasts up to 12 hours with a marvelous shine.",
    category: "Make Up",
  },
  {
    id: "product93",
    brand: "Kiko",
    productTitle: "Unlimited Double Touch 118",
    image: p93,
    shades: "118 Orchid",
    price: 10.99,
    description:
      "Kiko Unlimited Double Touch: Two steps to bold and long-lasting color! This innovative lipstick combines a matte base with high-pigment color and a glossy top layer. Get intense, smear-proof color that lasts up to 12 hours with a marvelous shine.",
    category: "Make Up",
  },
  {
    id: "product133",
    brand: "Douglas",
    productTitle: "Make-Up Big Bronzer - Matte",
    image: p133,
    shades: "Warm Sand",
    price: 19.99,
    description:
      "Douglas Make-Up Big Bronzer - Matte delivers sun-kissed radiance with a velvety matte finish. Its oversized format ensures easy application, while the matte formula adds warmth and dimension to the complexion. Achieve a natural, luminous glow with this versatile bronzer.",
    category: "Make Up",
  },
  {
    id: "product94",
    brand: "Kiko",
    productTitle: "Unlimited Double Touch 119",
    image: p94,
    shades: "119 Rhododendron Pink",
    price: 10.99,
    description:
      "Kiko Unlimited Double Touch: Two steps to bold and long-lasting color! This innovative lipstick combines a matte base with high-pigment color and a glossy top layer. Get intense, smear-proof color that lasts up to 12 hours with a marvelous shine.",
    category: "Make Up",
  },
  {
    id: "product95",
    brand: "Kiko",
    productTitle: "Unlimited Double Touch 131",
    image: p95,
    shades: "131 Spice",
    price: 10.99,
    description:
      "Kiko Unlimited Double Touch: Two steps to bold and long-lasting color! This innovative lipstick combines a matte base with high-pigment color and a glossy top layer. Get intense, smear-proof color that lasts up to 12 hours with a marvelous shine.",
    category: "Make Up",
  },
  {
    id: "product96",
    brand: "Kiko",
    productTitle: "Full Coverage Concealer",
    image: p96,
    shades: "01 light",
    price: 11.99,
    description:
      "This ultra-concealing formula effortlessly hides blemishes, wounds, and dark circles. It's lightweight, blends seamlessly, and lasts all day. Even oily skin stays shine-free thanks to the oil-absorbing complex. Get a flawless finish in a convenient, travel-friendly size!",
    category: "Make Up",
  },
  {
    id: "product97",
    brand: "Kiko",
    productTitle: "Holiday Première Lasting Duo Eyepencil 02",
    image: p97,
    shades: "02 Crescendo",
    price: 11.99,
    description:
      "This two-in-one eyeliner features a matte and metallic shade. Define your eyes with the rich matte side, then add sparkle with the dazzling metallic. Easy to blend and long-lasting, it creates endless eye looks for day or night!",
    category: "Make Up",
  },
  {
    id: "product98",
    brand: "Kiko",
    productTitle: "Holiday Première Lasting Duo Eyepencil 02",
    image: p98,
    shades: "01 Prelude",
    price: 7.99,
    description:
      "This two-in-one eyeliner features a matte and metallic shade. Define your eyes with the rich matte side, then add sparkle with the dazzling metallic. Easy to blend and long-lasting, it creates endless eye looks for day or night!",
    category: "Make Up",
  },
  {
    id: "product99",
    brand: "Kiko",
    productTitle: "Smart Fusion Lip Pencil",
    image: p99,
    shades: "18 Blackberry",
    price: 5.99,
    description:
      "This creamy pencil glides on smoothly for precise lip definition. Available in 36 extraordinary shades to match any lipstick or create a natural lip look. Plus, the long-lasting formula helps your lipstick last longer!",
    category: "Make Up",
  },
  {
    id: "product100",
    brand: "Kiko",
    productTitle: "New Lasting Matte Veil Liquid Lip Colour",
    image: p100,
    shades: "03 Mocha",
    price: 12.99,
    description:
      "This weightless liquid lipstick delivers rich, vibrant color with a stunning matte finish. Feels like a second skin, incredibly comfortable to wear all day long. Non-drying, just amazing color, kiss-resistant, lasting from sunrise to sunset.",
    category: "Make Up",
  },
  {
    id: "product101",
    brand: "Kiko",
    productTitle: "Skin Trainer Cc Blur",
    image: p101,
    shades: "01 Light",
    price: 19.99,
    description:
      "This CC cream combines color correction, blurring technology, and a touch of radiance. It evens out skin tone, minimizes imperfections, and leaves you with a healthy, natural glow. Plus, it hydrates your skin and keeps it shine-free! Get the perfect base for makeup or wear it alone for a fresh, flawless look.",
    category: "Make Up",
  },
  {
    id: "product102",
    brand: "Kiko",
    productTitle: "Stellar Love Galactic Duo Blush",
    image: p102,
    shades: "01 Blush Crish",
    price: 14.99,
    description:
      "This unique compact combines a soft powder blush for a subtle flush and a creamy blush for a luminous glow. Play with textures or blend them together for a customizable, long-lasting glow that complements every skin tone.",
    category: "Make Up",
  },
  {
    id: "product103",
    brand: "Kiko",
    productTitle: "New Bright Quartet Eyeshadow Palette",
    image: p103,
    shades: "04 Smokey Eyes Profusion",
    price: 15.99,
    description:
      "This handy palette features four fabulous shades in a blend of matte and pearl finishes. They're super blendable, allowing you to create endless looks, from soft daytime glow to the smokiest of evenings. Plus, the baked formula provides rich color payoff that lasts all day!",
    category: "Make Up",
  },
  {
    id: "product104",
    brand: "Bella",
    productTitle: "Solar Opulence Eye Quad",
    image: p104,
    shades: "The Sun",
    price: 29.99,
    description:
      "This pocket-sized palette features four irresistible shades inspired by the sun's radiance. Next-generation eye shadows, talc-free, are formulated for intense color payoff and a luxurious finish.",
    category: "Make Up",
  },
  {
    id: "product106",
    brand: "MAC",
    productTitle: "Extra Dimension Eye Shadow",
    image: p106,
    shades: "Smoky Mauve",
    price: 13.99,
    description:
      "This unique formula combines the smooth glide of a cream, the easy blendability of a liquid, and the weightless finish of a powder. Enjoy 10 hours of vibrant, crease-resistant color with a prismatic sparkle that delights every eye look.",
    category: "Make Up",
  },
  {
    id: "product107",
    brand: "Armani ",
    productTitle: "Lip Maestro Mini Set",
    image: p107,
    shades: "200, 405, 501",
    price: 39.99,
    description:
      "This Armani beauty essential contains two mini Lip Maestro lipsticks in iconic shades. Experience award-winning formulas with intense colors, maximum comfort, and a rich, velvety matte finish.",
    category: "Make Up",
  },
  {
    id: "product108",
    brand: "Armani",
    productTitle: "Lip Maestro 205",
    image: p108,
    shades: "205 Fiamma",
    price: 11.99,
    description:
      "This vibrant and warm orange-red shade with a shimmering finish instantly brightens your smile. The lightweight, creamy texture glides on effortlessly, delivering rich color with a velvety matte finish that feels comfortable for hours.",
    category: "Make Up",
  },
  {
    id: "product109",
    brand: "Yves Saint Laurent",
    productTitle: "Rouge Pur Couture ",
    image: p109,
    shades: "1966 Rouge Libre",
    price: 24.99,
    description:
      "This iconic formula delivers rich, saturated color in a beautiful satin finish. But it's not just about the look. Rouge Pur Couture nourishes your lips with antioxidant care, keeping them feeling soft and hydrated with every use.",
    category: "Make Up",
  },
  {
    id: "product110",
    brand: "Bella",
    productTitle: "Lumi Gloss",
    image: p110,
    shades: "Glowing Pink",
    price: 4.99,
    description:
      "This universal lip gloss is your secret weapon for luscious, glossy lips. Lumi-Gloss provides a light touch with a 3D effect and a super shiny finish, all thanks to its unique blend of reflective pearls.",
    category: "Make Up",
  },
  {
    id: "product113",
    brand: "I Heart Revolution",
    productTitle: "Chocolate Brow Wax",
    image: p113,
    shades: "Clear",
    price: 5.99,
    description:
      "This clear brow wax tames your brows without stiffness, leaving them looking groomed and defined. Plus, it comes in a convenient pot with a spoolie for easy application.",
    category: "Make Up",
  },

  {
    id: "product114",
    brand: "Caia",
    productTitle: "Hydra Exilir",
    image: p114,
    price: 19.99,
    description:
      "This luxurious hair serum is your secret weapon for hydrated, healthy-looking hair. Caia Hydra Elixir is packed with hyaluronic acid, known for its powerful hydrating properties, to deeply nourish your scalp and add moisture to dry strands.",
    category: "HairCare",
  },
  {
    id: "product115",
    brand: "Fenty Beauty",
    productTitle: "Gloss Bomb Universal Lip Luminizer Fenty Glow",
    image: p115,
    shades: "Fenty Glow",
    price: 15.99,
    description:
      "Enriched with shea butter, Gloss Bomb nourishes the lips from within, making them look healthy and kissable.",
    category: "Make Up",
  },
  {
    id: "product116",
    brand: "Fenty Beauty",
    productTitle: "Gloss Bomb Universal Lip Luminizer Sweet Mouth",
    image: p116,
    unavailable: "Our of Stock",
    shades: "Sweet Mouth",
    price: 15.99,
    description:
      "Enriched with shea butter, Gloss Bomb nourishes the lips from within, making them look healthy and kissable.",
    category: "Make Up",
  },
  {
    id: "product117",
    brand: "Fenty Beauty",
    productTitle: "Gloss Bomb Universal Lip Luminizer Hot Chcolit",
    image: p117,
    shades: "Hot Chcolit",
    price: 15.99,
    description:
      "Enriched with shea butter, Gloss Bomb nourishes the lips from within, making them look healthy and kissable.",
    category: "Make Up",
  },
  {
    id: "product118",
    brand: "Fenty Beauty",
    productTitle: "Gloss Bomb Universal Lip Luminizer Fu$$y",
    image: p118,
    unavailable: "Our of Stock",
    shades: "Fu$$y",
    price: 15.99,
    description:
      "Enriched with shea butter, Gloss Bomb nourishes the lips from within, making them look healthy and kissable.",
    category: "Make Up",
  },
  {
    id: "product119",
    brand: "Laura Geller",
    productTitle: "The Best of the Best Baked Face Palette",
    image: p119,
    price: 39.99,
    description:
      "Bronzer, blush, highlighter, and mascara. Baked formulas for a perfect and radiant complexion. Day or night looks, all in one!",
    category: "Make Up",
  },
  {
    id: "product120",
    brand: "Fenty Beauty",
    productTitle: "Eazy Drop Blur + Smooth Tint Stick",
    image: p120,
    shades: "11 Medium",
    price: 23.99,
    description:
      "Fenty Beauty Eaze Drop Stick is your secret to natural-looking skin. This lightweight, buildable coverage melts for a smooth, blurred finish that lasts. It's easy to use on the go and moisturizes throughout the day!",
    category: "Make Up",
  },
  {
    id: "product121",
    brand: "Charlotte Tilbury",
    productTitle: "Magic Away",
    image: p121,
    price: 19.99,
    shades: "5.5 Medium",
    description:
      "Charlotte Tilbury's Magic Away concealer! This magical wand hides dark circles, spots, and imperfections for a flawless, second-skin finish. Full coverage, lightweight blend, and long-lasting – say goodbye to makeup meltdowns!",
    category: "Make Up",
  },
  {
    id: "product122",
    brand: "Victoria Beckkham",
    productTitle: "Contour Stylus",
    image: p122,
    price: 19.99,
    shades: "Marble",
    description:
      "Victoria Beckham Beauty's contour stylus sculpts and defines! This creamy pencil blends effortlessly, creating natural shadows for a more defined and lifted look. It's your shortcut to cheekbone magic!",
    category: "Make Up",
  },
  {
    id: "product123",
    brand: "MAC",
    productTitle: "Liquidlast 24-hour Waterproof Liner",
    image: p123,
    price: 13.99,
    description:
      "Sharp lines, worn all day. MAC's Liquidlast Liner provides intense, waterproof color with a smudge-proof finish. The precise applicator creates dramatic or defined eye looks.",
    category: "Make Up",
  },
  {
    id: "product124",
    brand: "Rare Beauty",
    productTitle: "Positive Light Silky Touch Highlighter",
    image: p124,
    price: 25.99,
    shades: "Mesmerize",
    description:
      "Radiant glow, lightweight blend. Rare Beauty's highlighter provides buildable shimmer with a soft touch. It flatters all skin tones and lasts all day. Get your inner glow!",
    category: "Make Up",
  },
  {
    id: "product125",
    brand: "Rare Beauty",
    productTitle: "Positive Light Liquid Luminizer Outshine",
    image: p125,
    price: 23.99,
    shades: "Outshine",
    description:
      "Rare Beauty's liquid highlighter blends effortlessly for a natural, buildable glow. Lightweight and nourishing, it enhances all skin tones for a radiant look all day long.",
    category: "Make Up",
  },
  {
    id: "product128",
    brand: "Coco Chanel",
    productTitle: "Mademoiselle",
    image: p128,
    price: 49.99,
    size: 100,
    description:
      "It's a scent designed for the modern woman, described as bold and with a surprisingly fresh, amber fragrance. It is known for its sophisticated and feminine scent, which is perfect for both day and night wear.",
    category: "Parfume",
  },
];
function getProductData(id) {
  var productData = productsData.find((product) => product.id === id);
  if (productData === undefined) {
    console.log("NOT EXISTS" + id);
    return undefined;
  }
  return productData;
}

export { productsData, getProductData };

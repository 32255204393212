import React, { useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import ContactSection from "../components/homePageComponents/ContactSection";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Rreth nesh | Girly Glam";
  }, []);
  return (
    <Container>
      <title>
        <img src={logo} alt="" />
        <h1>GIRLY GLAM</h1>
      </title>
      <div className="mission">
        <h2>Misioni jone</h2>
        <p>
          At Girly Glam, our mission is to redefine beauty by empowering
          individuals to embrace their unique glow and express themselves with
          confidence. We are dedicated to providing high-quality, cruelty-free
          cosmetics that not only enhance natural beauty, but also promote
          self-love and authenticity. Through innovation, inclusivity and
          sustainability, we aspire to inspire a positive beauty culture that
          celebrates diversity and encourages everyone to embrace their own
          definition of beauty. Our commitment extends beyond cosmetics; we aim
          to contribute to a world where beauty is a force for good by
          supporting causes that promote well-being and care for the
          environment. Join us on this transformative beauty journey, where each
          product reflects our commitment to creating a more beautiful and
          compassionate world.
        </p>
      </div>
      <ContactSection />
    </Container>
  );
};
const Container = styled.div`
  padding: 1em 0 6em 0%;
  title {
    padding: 0 7%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;
    img {
      height: 30vh;
      opacity: 0.2;
      position: absolute;
      z-index: -1;
    }
    h1 {
      margin-top: 0.7em;
      font-size: 11vw;
      color: #4939341d;
    }
  }
  .mission {
    padding: 0 7%;
    text-align: center;
    h2 {
      font-size: 3em;
      font-family: "Montserrat", sans-serif;
      color: #5e524e;
      font-weight: 700;
    }
    p {
      color: gray;
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;

export default AboutUs;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProductsBanner from "../components/productsPageComponents/ProductsBanner";
import { productsData } from "../data/productsData";
import Product from "../components/productsPageComponents/Product";
import { motion, AnimatePresence } from "framer-motion";
import ProductsQuote from "../components/productsPageComponents/ProductsQuote";
import Banner from "../components/homePageComponents/Banner";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Produktet | Girly Glam";
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCategory, setFilteredCategory] = useState("All"); // Default category is "All"

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterByCategory = (category) => {
    setFilteredCategory(category);
  };

  const filterAndSearchProducts = () => {
    return productsData.filter(
      (item) =>
        (item.productTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.brand.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (filteredCategory === "All" || item.category === filteredCategory)
    );
  };

  const filteredProducts = filterAndSearchProducts();

  return (
    <Container>
      <Banner />
      <ProductsQuote />
      <div className="products">
        <div className="searchBar">
          <motion.input
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 100, opacity: 0 }}
            transition={{
              type: "spring",
              delay: 1,
              duration: 1,
            }}
            type="search"
            placeholder="Search products by name or brand..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="filtering">
          <button
            onClick={() => filterByCategory("All")}
            style={{
              background:
                filteredCategory === "All" ? "#755f3973" : "#ecdcc073",
              color: filteredCategory === "All" ? "#ffffff" : "#6e6551",
              boxShadow: "0 0 10px 1px #ecdcc073",
            }}
          >
            Te gjitha
          </button>
          <button
            onClick={() => filterByCategory("Skincare")}
            style={{
              background:
                filteredCategory === "Skincare" ? "#755f3973" : "#ecdcc073",
              color: filteredCategory === "Skincare" ? "#ffffff" : "#6e6551",
              boxShadow: "0 0 10px 1px #ecdcc073",
            }}
          >
            Skincare
          </button>
          <button
            onClick={() => filterByCategory("Make Up")}
            style={{
              background:
                filteredCategory === "Make Up" ? "#755f3973" : "#ecdcc073",
              color: filteredCategory === "Make Up" ? "#ffffff" : "#6e6551",
              boxShadow: "0 0 10px 1px #ecdcc073",
            }}
          >
            Make Up
          </button>
          <button
            onClick={() => filterByCategory("Parfume")}
            style={{
              background:
                filteredCategory === "Parfume" ? "#755f3973" : "#ecdcc073",
              color: filteredCategory === "Parfume" ? "#ffffff" : "#6e6551",
              boxShadow: "0 0 10px 1px #ecdcc073",
            }}
          >
            Parfume
          </button>
          <button
            onClick={() => filterByCategory("Dyson")}
            style={{
              background:
                filteredCategory === "Dyson" ? "#755f3973" : "#ecdcc073",
              color: filteredCategory === "Dyson" ? "#ffffff" : "#6e6551",
              boxShadow: "0 0 10px 1px #ecdcc073",
            }}
          >
            Dyson
          </button>
        </div>

        <motion.div
          className="items"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          {filteredProducts.map((item) => (
            <Product key={item.productId} item={item} />
          ))}
        </motion.div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  .products {
    margin-bottom: 7em;
    .filtering {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
      margin: auto;
      padding: 20px;
      button {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        border-radius: 20px;
        background: #ecdcc073;
        border: none;
        padding: 10px 20px;
        color: #6e6551;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          background: #b39f7c73;
        }
      }
    }
    .searchBar {
      display: flex;
      justify-content: center;
      margin: 2em;
      input {
        border: none;
        outline: none;
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        color: #383838;
        padding: 14px 24px;
        box-shadow: 0 4px 12px 2px #b8b8b8;
        border-radius: 30px;
        width: 500px;
        ::placeholder {
          color: #a52c2c;
        }
      }
    }
    .items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3em 1em;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 580px) {
    .products {
      .filtering {
        flex-wrap: wrap;
      }
    }
  }
`;

export default Products;

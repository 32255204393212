import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { RiInstagramFill } from "react-icons/ri";
import styled from "styled-components";

const ContactSection = () => {
  return (
    <Container>
      <motion.div
        className="left"
        animate={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: -200 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.4,
          type: "spring",
        }}
      >
        <h1>Pyetje apo sygjerime? Na Kontaktoni tani.</h1>
        <p>
          Zbuloni bukurinë tuaj me koleksionin dhe udhëzimet tona të shkëlqyera
          të kozmetikës.
        </p>
        <Link
          to={"https://www.instagram.com/girlyglam.al/"}
          target="_blank"
          className="link-styles"
        >
          <RiInstagramFill className="icon" />
        </Link>
      </motion.div>
      <motion.form
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          delay: 0.4,
        }}
      >
        <h2>Na Kontakto</h2>
        <input
          type="text"
          name="name"
          placeholder="Emri dhe Mbiemri"
          required
        />
        <input type="email" name="email" placeholder="Email Adresa" required />
        <input
          type="phone"
          name="phoneNumber"
          placeholder="Numri te telefonit"
          required
        />
        <textarea
          cols="20"
          rows="4"
          name="message"
          placeholder="Mesazhi"
          required
        ></textarea>
        <button type="submit">DERGO</button>
      </motion.form>
    </Container>
  );
};
const Container = styled.div`
  color: #7a7055;
  background: var(--primaryBackgroundColor);
  padding: 7em 8%;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat", cursive;
  background-image: var(--bgGradient3);
  .alert {
    position: fixed;
    top: 5em;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 12px 1px var(--shadowColor);
    padding: 14px 17px;
    border-radius: 20px 0 0 20px;
    transition: 0.4s;
    .icon {
      color: #119e11;
    }
  }
  .left {
    margin-top: 5%;
    width: 65%;
    h1 {
      font-family: "Lexend", sans-serif;
      font-size: 4em;
      line-height: 1.1em;
      color: #7e716d;
    }
    p {
      margin-right: 17%;
      color: #9b907f;
      font-weight: 300;
      margin-top: 1em;
      max-width: 400px;
    }
    .icon {
      font-size: 3em;
      margin-top: 10px;
      color: #5e524e;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 700px;
    padding: 2em;
    h2 {
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
    }
    input,
    textarea {
      font-family: "Montserrat", cursive;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid var(--primaryColorDark);
      background: none;
      color: var(--fontPrimaryColor);
      outline: none;
      ::placeholder {
        color: var(--fontSecondaryColor);
      }
    }
    button {
      margin-top: 1em;
      display: flex;
      align-self: flex-start;
      margin: auto;
      background: #5e524e;
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      box-shadow: 0 4px 12px 2px #b8b8;
      :hover {
        color: white;
        background: var(--primaryColor);
      }
    }
  }
  @media (max-width: 1300px) {
    padding: 4em 7%;
    .left {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .left {
      width: 100%;
      h1 {
        font-size: 3em;
      }
      p {
        text-align: center;
        margin: auto;
        max-width: 90%;
      }
    }
    form {
      width: 90%;
    }
  }
  @media (max-width: 550px) {
    padding: 6em 6% 4em 6%;
    form {
      width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 550px) {
    .left {
      h1 {
        font-size: 2.4em;
      }
    }
  }
  @media (max-width: 410px) {
    .left {
      h1 {
        font-size: 2em;
      }
    }
  }
`;
export default ContactSection;

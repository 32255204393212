import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import img1 from "../../assets/homeAssets/bannerImg1.png";
import img2 from "../../assets/homeAssets/bannerImg2.jpg";
import img3 from "../../assets/homeAssets/bannerImg3.png";
import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <Swiper
        className="swiper leftSwiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={10}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3700,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="slide">
          <div className="left">
            <h1>It's glow time</h1>
            <p>
              Eksploroni më të mirat e bukurisë: grimin, kujdesin e lëkurës dhe
              parfumet magjepsëse.
            </p>
            <div className="btns">
              <Link to={"/about-us"}>
                <button>PËR NE</button>
              </Link>
              <Link to={"/products"} className="link-styles">
                <button>EKSPLORO</button>
              </Link>
            </div>
          </div>
          <img src={img1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide ">
          <div className="left">
            <h1>Zotëroje aromën tënde.</h1>
            <p>
              Zbuloni një botë aromash të parezistueshme që i përshtaten çdo
              rasti.
            </p>
            <div className="btns">
              <Link to={"/about-us"}>
                <button>PËR NE</button>
              </Link>
              <Link to={"/products"}>
                <button>EKSPLORO</button>
              </Link>
            </div>
          </div>
          <img src={img2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide ">
          <div className="left">
            <h1>Lumturia e lëkurës suaj.</h1>
            <p>
              Shndërroni rutinën tuaj të kujdesit të lëkurës në një ritual të
              kujdesit për veten dhe kënaqësisë.
            </p>
            <div className="btns">
              <Link to={"/about-us"}>
                <button>PËR NE</button>
              </Link>
              <Link to={"/products"}>
                <button>EKSPLORO</button>
              </Link>
            </div>
          </div>
          <img src={img3} alt="" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  padding: 0 0 5em 0%;
  .swiper {
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3em;
      width: 84%;
      height: auto;
      background: var(--primaryColor);
      /* border-radius: 30px; */
      padding: 3em 4em;
      img {
        height: 400px;
        max-width: 48%;
        object-fit: cover;
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 1em;
        h1 {
          font-family: "Lexend", sans-serif;
          font-size: 4em;
          color: #5e524e;
        }
        p {
          color: gray;
        }
        .btns {
          display: flex;
          gap: 10px;
          button {
            background-color: #5e524e;
            border: none;
            color: white;
            padding: 7px 14px;
            font-size: 18px;
            border-radius: 20px;
            font-family: "Poppins", cursive;
            cursor: pointer;
            transition: 0.4s;
            :hover {
              box-shadow: 0 0 14px 2px var(--shadowColor);
            }
          }
          :nth-child(2) {
            color: white;
          }
        }
      }
    }
    .slide2 {
      background: #f1f1f1;
    }
    .slide3 {
      background: #ddd5cfd6;
    }
  }
  .swiper-pagination {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #554f4f; /* Change to your desired active color */
    opacity: 1;
  }
  @media (min-width: 1500px) {
    .swiper {
      .slide {
        padding: 3em 7%;
        img {
          width: 49%;
          height: auto;
        }
        .left {
          h1 {
            font-size: 5.4vw;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .swiper {
      .slide {
        width: 99%;
        gap: 1em;
        img {
          width: 40%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 785px) {
    .swiper {
      margin-top: 2em;
      .slide {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2em;
        padding: 2em 3em;
        img {
          width: 100%;
          max-width: 100%;
        }
        .left {
          align-items: center;
          text-align: center;
          h1 {
            font-size: 12vw;
          }
        }
      }
      .slide2 {
        .left {
          h1 {
            font-size: 14vw;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .swiper {
      .slide {
        width: 99%;
        height: auto;
        .left {
          h1 {
            line-height: 1em;
          }
        }
      }
    }
  }
`;

export default Banner;

import React from "react";
import styled from "styled-components";

const ProductsQuote = () => {
  return (
    <Container>
      <h1>
        Confidence in <br /> Every Product
      </h1>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 7% 1em 7%;
  h1 {
    font-size: 10vw;
    color: #ecdcc073;
    font-weight: 900;
    line-height: 0.5em;
  }
`;

export default ProductsQuote;

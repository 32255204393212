import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, EffectCreative } from "swiper/modules";
import YSLLibre from "../../assets/homeAssets/YSLLibre.jpg";
import theOrd from "../../assets/homeAssets/scImg5.png";
import theOrd2 from "../../assets/homeAssets/scImg1.png";
import rbImg from "../../assets/homeAssets/rb.jpg";
import cerave from "../../assets/homeAssets/cerave.jpg";
import { Link } from "react-router-dom";

const BestSellingFS = () => {
  return (
    <Container>
      <h1 className="bgText">GIRLY GLAM</h1>
      <title>
        <h2>Produktet më të shitura</h2>
        <p>Këtu janë 4 produktet më preferuara për klientët tanë.</p>
      </title>
      <Swiper
        className="swiper leftSwiper"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay, EffectCreative]}
        slidesPerView={"1"}
        centeredSlides={true}
        speed={300}
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", "20%", 0],
          },
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>Perfumes</h4>
            <h2>YSL</h2>
            <h1>Libre</h1>
            <p>
              YSL Libre është një aromë magjepsëse që mishëron lirinë dhe
              fuqizimin. I lëshuar nga Yves Saint Laurent, ky parfum është një
              përzierje harmonike e esencës së livandës, luleve të portokallit
              dhe myshkut. Aroma e guximshme dhe sensuale feston
              individualitetin dhe vetë-shprehjen, duke lënë një përshtypje të
              qëndrueshme. Me dizajnin e tij elegant dhe modern, parfumi YSL
              Libre është një simbol i vetëbesimit dhe sofistikimit.
            </p>
            <div className="btns">
              <Link to={"/products/product11"}>
                <button>SHIKO ME SHUME</button>
              </Link>
            </div>
          </div>
          <img src={YSLLibre} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>Skincare</h4>
            <h2>The Ordinary</h2>
            <h1>Face Serum</h1>
            <p>
              The Ordinary Face Serum është një kujdes thelbësor për
              ripërcaktimin e thjeshtësisë dhe efikasitetit. I krijuar nga The
              Ordinary, ai përmban një përzierje të fuqishme përbërësish aktivë
              për të synuar shqetësime specifike të lëkurës. Ky serum i lehtë
              është projektuar për integrim të lehtë në çdo rutinë të kujdesit
              të lëkurës, duke ofruar një zgjidhje të drejtpërdrejtë por të
              fuqishme.
            </p>
            <div className="btns">
              <Link to={"/products/product71"}>
                <button>SHIKO ME SHUME</button>
              </Link>
            </div>
          </div>
          <img src={theOrd} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>Make Up</h4>
            <h2>Rare Beaity</h2>
            <h1>Liquid Highlighter</h1>
            <p>
              Rare Beauty Liquid Highlighter është një zbulim rrezatues në grim.
              Krijuar nga Rare Beauty, kjo formulë ndriçuese rrit pa mundim
              shkëlqimin tuaj natyral. Me një konsistencë të lehtë dhe të
              ndërtueshme, theksuesi i lëngshëm përzihet pa probleme, duke
              ofruar një përfundim të shkëlqyeshëm që i përshtatet të gjitha
              nuancave të lëkurës. Paketimi i dizajnuar me kujdes pasqyron
              përkushtimin e Rare Beauty për gjithëpërfshirjen dhe
              vetë-shprehjen.
            </p>
            <div className="btns">
              <Link to={"/products/product12"}>
                <button>SHIKO ME SHUME</button>
              </Link>
            </div>
          </div>
          <img src={rbImg} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>Skincare</h4>
            <h2>CeraVe</h2>
            <h1>Hydrating Cleanser</h1>
            <p>
              CeraVe Hydrating Cleanser nga CeraVe është një kujdes i butë i
              domosdoshëm për lëkurën. Pa aroma dhe i mbushur me ceramide dhe
              acid hialuronik, pastron dhe hidraton të gjitha llojet e lëkurës.
              I miratuar nga dermatologu, siguron një ndjesi të freskët dhe të
              zhdërvjellët. Përqafoni thjeshtësinë dhe ushqimin me CeraVe për
              rutinën tuaj të përditshme të kujdesit të lëkurës.
            </p>
            <div className="btns">
              <Link to={"/products/product2"}>
                <button>SHIKO ME SHUME</button>
              </Link>
            </div>
          </div>
          <img src={cerave} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-200">
            <h4>Skincare</h4>
            <h2>The Ordinary</h2>
            <h1>Glyconil Acid</h1>
            <p>
              Zgjidhja e acidit glikolik të Ordinary është një ndryshim i lojës
              për kujdesin e lëkurës. E formuluar për eksfolim efektiv, kjo
              zgjidhje rinovon dhe rafinon strukturën e lëkurës. Me acid
              glikolik 7%, promovon një çehre rrezatuese duke mbetur pa alkool
              dhe vegan. Përmirësoni kujdesin tuaj për lëkurën me përkushtimin e
              The Ordinary ndaj thjeshtësisë dhe fuqisë.
            </p>
            <div className="btns">
              <Link to={"/products/product7"}>
                <button>SHIKO ME SHUME</button>
              </Link>
            </div>
          </div>
          <img src={theOrd2} alt="" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  overflow: hidden;
  position: relative;
  .bgText {
    position: absolute;
    font-size: 14vw;
    top: -47px;
    width: 100%;
    text-align: center;
    z-index: 0;
    opacity: 0.07;
  }
  title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    text-align: center;
    z-index: 2;
    h2 {
      color: #807973;
      font-family: "Montserrat", sans-serif;
      font-size: 2em;
      font-weight: 600;
      z-index: 2;
    }
    p {
      margin-right: 0;
      color: gray;
      z-index: 2;
    }
  }
  .swiper {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 2em;
    .slide {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #eee5deab;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      .left {
        padding: 10% 3% 10% 10%;
        display: flex;
        flex-direction: column;
        h4 {
          color: #707070;
          margin-bottom: 1em;
        }
        h2 {
          font-size: 3vw;
          font-weight: 300;
        }
        h1 {
          font-size: 4vw;
          font-family: "Lexend", sans-serif;
          color: #746c65;
        }
        p {
          padding: 1em 0;
        }
        .btns {
          display: flex;
          gap: 10px;
          button {
            background: #5e524e;
            color: white;
            border: none;
            padding: 7px 14px;
            font-size: 18px;
            border-radius: 20px;
            font-family: "Poppins", cursive;
            cursor: pointer;
            transition: 0.4s;
            box-shadow: -1px 2px 10px 2px #c2bcb8;
            :hover {
              box-shadow: 0 0 14px 2px var(--shadowColor);
            }
          }
        }
      }
      img {
        width: 47%;
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #554f4f; /* Change to your desired active color */
    opacity: 1;
  }
  @media (max-width: 760px) {
    .bgText {
      top: -27px;
    }
    .swiper {
      .slide {
        .left {
          padding: 10% 3% 10% 4%;
        }
      }
    }
  }
  @media (max-width: 700px) {
    .bgText {
      top: -7px;
    }
    .swiper {
      height: auto;
      .slide {
        gap: 0em;
        flex-direction: column-reverse;
        padding-bottom: 1em;
        .left {
          padding: 7%;
          text-align: center;
          align-items: center;
          h2 {
            font-size: 1.4em;
          }
          h1 {
            font-size: 7vw;
          }
          p {
            height: 100px;
            overflow-y: scroll;
            margin-bottom: 1em;
          }
        }
        img {
          width: 70%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .swiper {
      height: 80vh;
    }
  }
`;

export default BestSellingFS;

import React from "react";
import styled from "styled-components";
import { CartContext } from "../CartContext";
import { FaTrash } from "react-icons/fa";
import { useContext } from "react";
import { getProductData } from "../data/productsData";

const CartProduct = (props) => {
  const cart = useContext(CartContext);
  const id = props.id;
  const quantity = props.quantity;
  const productData = getProductData(id);
  return (
    <Container>
      <img className="image" src={productData.image} alt="" />
      <span>
        <h2>
          <span className="cartPSpan">
            <h4>{productData.category}</h4>
            <h3>{productData.productTitle}</h3>{" "}
          </span>
          <FaTrash
            onClick={() => cart.deleteFromCart(id)}
            className="deleteIcon"
          />
        </h2>
        <div className="quantity">
          <span>
            <button onClick={() => cart.removeOneFromCart(id)}>-</button>
            <h1>{quantity}</h1>
            <button onClick={() => cart.addOneToCart(id)}>+</button>
          </span>
          <h3>{(quantity * productData.price).toFixed(2)}€</h3>
        </div>
      </span>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  .image {
    height: 120px;
    width: auto;
    border-radius: 0px;
    object-fit: contain;
    background-color: white;
  }
  .cartPSpan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 1em;
    h2 {
      width: 100%;
      display: flex;
      span {
        display: flex;
      }
    }
    h4 {
      text-transform: capitalize;
      font-size: 17px;
      font-weight: 300;
      color: gray;
    }
  }
`;

export default CartProduct;

import React, { useEffect } from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";

const SuccessPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Success Order Page | Girly Glam";
  }, []);
  return (
    <Container>
      <FaCheckCircle className="icon" />
      <h1>Urime!</h1>
      <h2>
        Porosia juaj u be me sukses dhe do te arrij tek ju shume shpejt :)
      </h2>
      <p>Faleminderit per blerjen tuaj!</p>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 7% 9em 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  text-align: center;
  .icon {
    color: green;
    font-size: 10em;
  }
  h1 {
    color: #8d7f7a;
    font-weight: 500;
  }
  h2 {
    font-weight: 400;
    color: #8d7f7a;
  }
`;

export default SuccessPage;

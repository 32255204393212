import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { TbShoppingBagSearch } from "react-icons/tb";
import { FaHome, FaPhoneAlt } from "react-icons/fa";
import { FaCircleInfo, FaCartShopping } from "react-icons/fa6";
import { BsCartX } from "react-icons/bs";
import { CartContext } from "../CartContext";
import CartProduct from "./CartProduct";

const Navbar = () => {
  const cart = useContext(CartContext);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const pathname = location.pathname;
  const [modal, setModal] = useState(false);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  useEffect(() => {
    if (pathname === "/") {
      setActiveTab("home");
    } else if (pathname === "/products") {
      setActiveTab("products");
    } else if (pathname === "/about-us") {
      setActiveTab("about-us");
    } else if (pathname === "/contact") {
      setActiveTab("contact");
    }
  }, [pathname]);
  return (
    <Container>
      <Link to={"/"} className="link-styles">
        <FaHome className="icon" />
      </Link>
      <Link to={"/products"} className="link-styles">
        <TbShoppingBagSearch className="icon" />
      </Link>
      <Link to={"/about-us"} className="link-styles">
        <FaCircleInfo className="icon" />
      </Link>
      <Link to={"/contact"} className="link-styles">
        <FaPhoneAlt className="icon" />
      </Link>
      <div className="cartIcon">
        <FaCartShopping onClick={() => setModal(!modal)} className="icon" />
        <h4>{productsCount}</h4>
      </div>
      <div className={modal ? "modal" : "closedModal"}>
        <span>
          <div>
            <FaCartShopping className="icon" />
            <h3>Shporta ime ({productsCount})</h3>
          </div>
          <button className="x" onClick={() => setModal(false)}>
            x
          </button>
        </span>
        <div className="items">
          {productsCount !== 0 ? (
            <div className="item">
              {cart.items.map((currentProduct, idx) => (
                <CartProduct
                  key={idx}
                  id={currentProduct.id}
                  quantity={currentProduct.quantity}
                />
              ))}
            </div>
          ) : (
            <div className="emptyCart">
              <BsCartX className="cartIcon" />
              <h3>Shporta juaj aktualisht është bosh.</h3>
            </div>
          )}
        </div>
        {productsCount !== 0 ? (
          <>
            <h2 className="totalPrice">
              Çmimi total: {cart.getTotalCost().toFixed(2)} €
            </h2>
            <Link
              onClick={() => setModal(false)}
              to={"/checkout"}
              className="link-styles"
            >
              <button>Checkout</button>
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};
const Container = styled.div`
  background: #faeddfb9;
  transition: 0.4s;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 10%;
  width: 100%;
  height: 65px;
  z-index: 201;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  .closedModal {
    right: -200vw;
    position: fixed;
    transition: 0.4s;
  }
  .icon {
    font-size: 28px;
    color: #5e524e;
  }
  .cartIcon {
    display: flex;
    position: relative;
    .icon {
      font-size: 28px;
      cursor: pointer;
    }
    h4 {
      padding: 2px 7px;
      top: -14px;
      color: white;
      right: -10px;
      border-radius: 10px;
      position: absolute;
      background-color: #a79181;
      font-weight: 300;
    }
  }
  .modal {
    position: fixed;
    bottom: 0%;
    right: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 400px;
    max-width: 440px;
    background: #27211f;
    color: white;
    padding: 2em 14px 2em 14px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 2px var(--shadowColor);
    transition: 0.4s ease-in-out;
    .emptyCart {
      margin: 2em;
      .cartIcon {
        font-size: 4em;
        margin: auto;
        text-align: center;
      }
      h3 {
        margin-top: 10px;
        font-weight: 300;
        text-align: center;
        color: #ebe0d3;
      }
    }
    span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      div {
        display: flex;
        align-items: center;
        gap: 1em;

        .icon {
          color: white;
        }
        h3 {
          font-weight: 300;
        }
      }
      .x {
        color: white;
        background: none;
        border: none;
        font-size: 1.7em;
        position: absolute;
        right: 1em;
      }
    }
    .items {
      margin-top: 2em;
      .item {
        width: 100%;
        display: flex;
        gap: 1em;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 150px;
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          gap: 8px;
          h2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
              font-size: 17px;
              font-weight: 400;
              width: 100%;
            }
            .icon {
              font-size: 1em;
              color: white;
            }
          }
          h4 {
            font-size: 17px;
            font-weight: 300;
            color: gray;
          }
          .quantity {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4px;
            span {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: flex-start;
                font-size: 30px;
                width: 30px;
                height: 30px;
                border: none;
                background: white;
                color: black;
                border-radius: 8px;
              }
              h1 {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
    .totalPrice {
      font-weight: 400;
      margin: 1em;
      text-align: center;
    }
    button {
      background: white;
      border: none;
      margin: auto;
      display: flex;
      color: #202020;
      padding: 7px 14px;
      font-size: 18px;
      border-radius: 20px;
      font-family: "Poppins", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  @media (max-width: 400px) {
    .modal {
      width: 97%;
    }
  }
`;

export default Navbar;

import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { productsData } from "../data/productsData";
import { useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import { CartContext } from "../CartContext";
import { motion, useAnimation, useInView } from "framer-motion";

const ProductPage = ({ item }) => {
  const { id } = useParams();
  const cart = useContext(CartContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Produkt | Girly Glam";
  }, []);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      {productsData
        .filter((item) => item.id === id)
        .map((product) => (
          <>
            {product.unavailable ? (
              <div className="img">
                <img src={product.image} alt="" />
                <h2>Out Of Stock</h2>
              </div>
            ) : (
              <img src={product.image} alt="" />
            )}
            <div className="right">
              <img src={logo} className="logo" alt="" />
              <h4>GirlyGlam</h4>
              <h3>{product.brand}</h3>
              <h2>{product.productTitle}</h2>
              {product.size && <h3>{product.size}ml</h3>}

              {product?.discountPrice ? (
                <span>
                  <h2 className="nPrice">{product.price}€</h2>
                  <h1 className="dPrice">{product?.discountPrice}€</h1>
                </span>
              ) : (
                <h1>{product.price}€</h1>
              )}
              <h3>
                Availability:{" "}
                {product.unavailable ? (
                  <span className="outOfStock">Out of Stock</span>
                ) : (
                  <span> In Stock</span>
                )}
              </h3>
              {product.shades && (
                <h3>
                  Shade: <span>{product.shades}</span>
                </h3>
              )}
              <p>{product.description}</p>
              <div className="btns">
                {product.unavailable ? (
                  <></>
                ) : (
                  <button
                    onClick={() =>
                      cart.addOneToCart(product.id, product.productTitle)
                    }
                  >
                    SHTO NE SHPORTE
                  </button>
                )}
              </div>
            </div>
          </>
        ))}
    </Container>
  );
};
const Container = styled.div`
  padding: 0em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  gap: 1em;
  .img {
    height: 90vh;
    max-width: 55%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      object-fit: cover;
      max-height: 700px;
      opacity: 0.4;
    }
    h2 {
      z-index: 99;
      opacity: 1;
      position: absolute;
      color: black;
      font-weight: 400;
      font-size: 2em;
      text-align: center;
    }
  }
  img {
    height: 90vh;
    max-width: 55%;
    max-height: 700px;
  }
  .right {
    display: flex;
    flex-direction: column;
    position: relative;
    .logo {
      position: absolute;
      right: 0;
      width: 200px;
      height: auto;
      top: -4em;
      opacity: 0.07;
    }
    img {
      width: 100px;
    }
    h4 {
      color: gray;
      font-weight: 500;
      margin-bottom: 10px;
    }
    span {
      margin: 7px 1em;
      display: flex;
      align-items: center;
      gap: 4px;
      .nPrice {
        color: gray;
        margin-top: 10px;
        font-weight: 300;
        text-decoration: line-through;
      }
    }
    h2 {
      color: #7a7263;
      font-weight: 400;
      margin-bottom: 10px;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: gray;
      text-align: center;
      span {
        color: #4d4d4d;
        font-weight: 500;
        text-align: center;
      }
      .outOfStock {
        color: red;
        text-align: center;
      }
    }
    h1 {
      margin: 10px;
      font-weight: 500;
      color: #6e665c;
    }
    p {
      color: gray;
      font-weight: 300;
      max-width: 600px;
      margin: 20px 0;
    }
    .btns {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 1em;
      button {
        padding: 10px 24px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 300;
        border: none;
        border-radius: 30px;
        background: #645e4dfb;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0 4px 12px 2px #b8b8b8;
        &:hover {
          background: #e9e9e9;
          color: gray;
        }
      }
      :nth-child(2) {
        background: var(--primaryColor);
        color: gray;
        &:hover {
          background: #645e4dfb;
          color: white;
        }
      }
    }
  }
  @media (max-width: 970px) {
    padding: 4em 4% 4em 0;
  }
  @media (max-width: 800px) {
    padding: 4em 4% 4em 4%;
    flex-direction: column;
    align-items: center;
    .img {
      height: auto;
      img {
        max-height: 470px;
      }
    }
    img {
      height: auto;
      max-height: 470px;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    .right {
      padding: 7%;
      align-items: center;
      text-align: center;
      .btns {
        gap: 8px;
        button {
          padding: 10px 17px;
          font-size: 17px;
        }
      }
    }
  }
`;

export default ProductPage;

import React from "react";
import styled from "styled-components";
import bannerImg1 from "../../assets/productsPageAssets/bannerImg1.png";
import bannerImg2 from "../../assets/productsPageAssets/bannerImg2.webp";
import bannerImg3 from "../../assets/productsPageAssets/bannerImg3.png";
import logo from "../../assets/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

const ProductsBanner = () => {
  return (
    <Container>
      <Swiper
        className="swiper leftSwiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={4}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="slide">
          <div className="left">
            <img className="logo" src={logo} alt="" />
            <h1>Make Up Origjinal dhe modern</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Perferendis, nesciunt?
            </p>
          </div>
          <img src={bannerImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide2">
          <div className="left">
            <img className="logo" src={logo} alt="" />
            <h1>Skincare efektiv dhe origjinal</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Perferendis, nesciunt?
            </p>
          </div>
          <img src={bannerImg2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide3">
          <div className="left">
            <img className="logo" src={logo} alt="" />
            <h1>Parfuma origjinal dhe joshës</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Perferendis, nesciunt?
            </p>
          </div>
          <img src={bannerImg3} alt="" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    width: 94%;
    background: #f1f5f7;
    img {
      width: 49%;
    }
    .left {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 0 8%;
      .logo {
        position: absolute;
        width: 17%;
        margin-top: -3em;
        z-index: -1;
        opacity: 0.08;
      }
      h1 {
        font-family: "Lexend", sans-serif;
        font-size: 4em;
      }
      p {
        color: gray;
      }
      .btns {
        display: flex;
        gap: 10px;

        :nth-child(2) {
          background: #2e2e2e;
          color: white;
        }
      }
    }
  }
  .slide2 {
    background: #ebeef4;
  }
  .slide3 {
    background: #ecdcc073;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #ada491; /* Change to your desired active color */
    opacity: 1;
  }
  @media (min-width: 1500px) {
    .swiper {
      .slide {
        padding: 3em 5%;
        img {
          width: 49%;
          height: auto;
        }
        .left {
          h1 {
            font-size: 5vw;
            line-height: 1.1em;
          }
        }
      }
    }
  }
  @media (max-width: 1300px) {
    .swiper {
      .slide {
        height: 80vh;
        .left {
          h1 {
            font-size: 3.7em;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .swiper {
      .slide {
        width: 99%;
        gap: 1em;
        img {
          width: 40%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 940px) {
    .swiper {
      margin-top: 2em;
      .slide {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2em;
        padding: 2em 3em;
        height: auto;
        img {
          width: 70%;
        }
        .left {
          .logo {
            margin-top: 0;
          }
          align-items: center;
          text-align: center;
          h1 {
            font-size: 7vw;
          }
        }
      }
      .slide2 {
        .left {
        }
      }
    }
  }
  @media (max-width: 650px) {
    .swiper {
      .slide {
        width: 99%;
        height: auto;
        .left {
          h1 {
            line-height: 1em;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .swiper {
      .slide {
        .left {
          h1 {
            font-size: 12vw;
          }
        }
      }
    }
  }
`;

export default ProductsBanner;

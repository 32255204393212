import React, { useContext } from "react";
import styled from "styled-components";
import { FaShoppingBag } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CartContext } from "../../CartContext";
import "react-toastify/dist/ReactToastify.css";

const Product = ({ item }) => {
  const cart = useContext(CartContext);
  const isInCart = cart.items.find((product) => product.id === item.id);

  return (
    <Container>
      <Link to={`${item.id}`}>
        {item.unavailable ? (
          <div className="imgOOS">
            <h2>Out of Stock</h2>
            <img src={item.image} alt="" />
          </div>
        ) : (
          <img src={item.image} alt="" />
        )}
      </Link>
      <div>
        <h5>{item.category}</h5>
        <h4>{item.brand}</h4>
        <Link to={`${item.id}`} className="link-styles">
          <h3>{item.productTitle}</h3>
        </Link>
        <span>
          {item?.discountPrice ? (
            <div>
              <h4 className="nPrice">{item.price}€</h4>
              <h3 className="dPrice">{item?.discountPrice}€</h3>
            </div>
          ) : (
            <div>
              <h3>{item.price}€</h3>
            </div>
          )}
          <FaShoppingBag
            onClick={() => {
              cart.addOneToCart(item.id);
            }}
            className="icon"
            style={{ color: isInCart ? "#1f912e" : "#615c54" }} // Change color based on whether the item is in the cart
          />
        </span>
      </div>
    </Container>
  );
};
const Container = styled.div`
  width: 270px;
  text-align: center;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  img {
    box-shadow: 0 0 7px 1px #f0edec;
    padding: 1px 1px 0 1px;
    width: 100%;
    height: 270px;
    object-fit: contain;
    border-radius: 20px 20px 0 0;
  }
  .imgOOS {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      filter: blur(1px);
      opacity: 0.5;
    }
    h2 {
      z-index: 99;
      opacity: 1;
      position: absolute;
      color: black;
      font-weight: 400;
      font-size: 2em;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 10px;
    border-left: 1px solid #f7f2eb;
    border-right: 1px solid #f7f2eb;
    h3 {
      color: #807973;
      font-weight: 500;
    }
    h4 {
      color: #61584c;
    }
    h5 {
      color: #a19a98;
      font-weight: 400;
    }
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px 20px 0px 20px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0 0 0 0;
        border: none;
        gap: 10px;
        h4 {
          text-decoration: line-through;
          color: gray;
          font-weight: 300;
        }
        h3 {
          font-weight: 700;
          color: #80786e;
          font-family: "Montserrat";
        }
      }
      .icon {
        font-size: 1.5em;
        color: #615c54;
        cursor: pointer;
      }
    }
  }
`;

export default Product;

import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../components/homePageComponents/Banner";
import Stats from "../components/homePageComponents/Stats";
import Brands from "../components/homePageComponents/Brands";
import Quote from "../components/homePageComponents/Quote";
import SkincareSlider from "../components/homePageComponents/SkincareSlider";
import BestSellingFS from "../components/homePageComponents/BestSellingFS";
import PerfumesSlider from "../components/homePageComponents/PerfumesSlider";
import ContactSection from "../components/homePageComponents/ContactSection";
import MakeUpSlider from "../components/homePageComponents/MakeUpSlider";
import ProductsBanner from "../components/productsPageComponents/ProductsBanner";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Girly Glam - Vendi i bukurise tende";
  }, []);
  return (
    <Container>
      <ProductsBanner />
      <Stats />
      <Brands />
      <BestSellingFS />
      <Quote />
      <SkincareSlider />
      <PerfumesSlider />
      <MakeUpSlider />
      <ContactSection />
      {/* <BestSelling /> */}
    </Container>
  );
};
const Container = styled.div`
  overflow-x: hidden;
`;

export default Home;

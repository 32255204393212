import React from "react";
import styled from "styled-components";

const Quote = () => {
  return (
    <Container>
      <h1>
        Makeup is art. <br /> Beauty is Spirit.
      </h1>
    </Container>
  );
};
const Container = styled.div`
  padding: 8em 7%;
  h1 {
    font-size: 7.4vw;
    font-family: "Lexend", sans-serif;
    color: #927b7476;
    line-height: 0.74em;
  }
  @media (max-width: 680px) {
    h1 {
      font-size: 10vw;
    }
  }
  @media (max-width: 500px) {
    padding: 4em 7%;
    h1 {
      font-size: 15vw;
      line-height: 0.7em;
    }
  }
`;

export default Quote;

import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import ASitesLogo from "../assets/ASites.png";

const Footer = () => {
  return (
    <Container>
      <div className="top">
        <div className="logo">
          <img src={logo} alt="" />
          <p>Not affiliated with any brands we source.</p>
        </div>
        <div className="right">
          <div className="column">
            <h3>Links</h3>
            <Link to={"/"} className="link-styles">
              <p>Home</p>
            </Link>
            <Link to={"/products"} className="link-styles">
              <p>Produktet</p>
            </Link>
            <Link to={"/about-us"} className="link-styles">
              <p>Rreth nesh</p>
            </Link>
            <Link to={"/contact"} className="link-styles">
              <p>Kontakti</p>
            </Link>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <div>
              <IoMail className="icon" />
              <h3>info@girlyglam-al.com</h3>
            </div>
            <div>
              <Link
                to={"https://www.instagram.com/girlyglam.al/"}
                target="_blank"
              >
                <RiInstagramFill className="icon" />
              </Link>
              <Link
                to={"https://www.instagram.com/girlyglam.al/"}
                target="_blank"
                className="link-styles"
              >
                <h3>@girlyglam.al</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <p>Designed and Developed by:</p>
        <Link
          to={"https://asitesagency.com/"}
          className="link-styles"
          target="_blank"
        >
          <img src={ASitesLogo} alt="" />
        </Link>
        <Link
          to={"https://asitesagency.com/"}
          className="link-styles"
          target="_blank"
        >
          <p>Visit ASites Agency</p>
        </Link>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 2em 7%;
  margin-bottom: 4em;
  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 230px;
      img {
        width: 100px;
      }
      p {
        text-align: center;
        font-weight: 300;
        color: gray;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      gap: 4em;
      .column {
        h3 {
          padding-bottom: 14px;
          color: #6d5d50;
        }
        p {
          color: #a7a297;
          cursor: pointer;
          padding: 7px 0;
          :hover {
            color: black;
          }
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        gap: 1em;
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            font-size: 3em;
            background: #6d5d50;
            padding: 10px;
            border-radius: 10px;
            color: white;
          }
          h3 {
            font-weight: 300;
            color: var(--fontSecondaryColor);
          }
        }
        span {
          display: flex;
          gap: 10px;
          .icon {
            font-size: 3em;
            background: var(--primaryColor);
            padding: 10px;
            border-radius: 10px;
            color: white;
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      text-align: center;
    }
    img {
      width: 140px;
    }
  }
  @media (max-width: 850px) {
    .top {
      flex-direction: column;
      gap: 3em;
      align-items: center;
      div {
        flex-wrap: wrap;
      }
    }
  }
`;

export default Footer;

import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { productsData } from "../data/productsData";
import { CartContext } from "../CartContext";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";

const CheckoutTest = ({ totalPrice }) => {
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const [shippingPrice, setShippingPrice] = useState(2);
  const [selectedCountry, setSelectedCountry] = useState("Shqiperi");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Checkout | Girly Glam";
  }, []);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );
  const subtotal = cart.items.reduce(
    (sum, product) => sum + product.quantity * product.price,
    0
  );

  const handleShippingChange = (e) => {
    const selectedOption = e.target.value;
    // Step 3: Update shipping price based on the selected option
    if (selectedOption === "Shqiperi") {
      setShippingPrice(2);
    } else if (selectedOption === "Kosove") {
      setShippingPrice(5);
    } else if (selectedOption === "Macedoni") {
      setShippingPrice(7);
    } else {
      setShippingPrice(2);
    }
  };
  const sendEmail = (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.checkValidity()) {
      //* If the form is not valid, display an error message for each invalid field
      form.reportValidity();
      return;
    }
    const formData = new FormData(e.target);

    //* Extract product details from the form data
    const productDetails = cart.items.map((product) => ({
      brand: product.brand,
      productTitle: product.productTitle,
      price: product.price,
      quantity: product.quantity,
    }));

    // Construct the email body as plain text
    const emailBody = `
        Name: ${formData.get("name")}
        Email: ${formData.get("email")}
        Phone: ${formData.get("phone")}
        Address: ${formData.get("street")}, ${formData.get(
      "city"
    )}, ${formData.get("country")}, ${formData.get("postalCode")}
        Message: ${formData.get("message")}
        Product Details:
${productDetails
  .map(
    (product) =>
      `${product.brand} - ${product.productTitle} - Quantity: ${product.quantity} - Price: €${product.price}`
  )
  .join("\n")}
  Total Price: €${totalPrice}
`;

    if (e.target.checkValidity()) {
      emailjs
        .sendForm(
          "girlyglam-orders",
          "template_9qjmj16",
          e.target,
          "L9ZNhiLlpJbhJF3cN",
          {
            to_email: formData.get("email"),
            message_html: emailBody,
          }
        )
        .then((res) => {
          toast.success("Form submitted successfully!");
          navigate("/success-page");
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Please fill in all fields.");
    }
  };
  const productDetailsText = cart.items
    .map((product) => {
      return `${product.brand} - ${product.productTitle} - Quantity: ${product.quantity} - Price: €${product.price} - Total: €${subtotal} `;
    })
    .join("\n");

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <ToastContainer />
      <>
        <motion.div
          ref={ref}
          className="left"
          variants={{
            hidden: { opacity: 0, x: -300 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          {cart.items.map((product) => (
            <>
              <div className="item">
                <img src={product.image} alt="" />
                <span>
                  <h4>{product.brand}</h4>
                  <h3>{product.productTitle}</h3>
                  <h4>
                    Sasia: <span>{product.quantity}</span>
                  </h4>
                  <h2>€{(product.quantity * product.price).toFixed(2)}</h2>
                </span>
              </div>
            </>
          ))}
          <div className="total">
            <span>
              <h3>Subtotal</h3>
              <h2>€{subtotal.toFixed(2)}</h2>
            </span>
            <span>
              <h3>Transporti</h3>
              <h2>€{shippingPrice.toFixed(2)}</h2>
            </span>
            <hr />
            <span>
              <h2>Totali</h2>
              <h1>€{(subtotal + shippingPrice).toFixed(2)}</h1>
            </span>
          </div>
        </motion.div>
        <hr />
        <motion.form
          className="right"
          onSubmit={sendEmail}
          variants={{
            hidden: { opacity: 0, x: 300 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          <input
            type="hidden"
            name="productDetails"
            value={productDetailsText}
          />
          <div className="contact">
            <label>Kontakti</label>
            <input
              type="email"
              name="email"
              placeholder="Email Addressa"
              required
            />
            <input
              type="phone"
              name="phone"
              placeholder="Numri i telefonit"
              required
            />
          </div>

          <div className="delivery">
            <label>Dergesa</label>
            <span>
              <input
                name="name"
                type="text"
                placeholder="Emri dhe Mbiemri"
                required
              />
              <select
                onChange={(e) => {
                  handleShippingChange(e);
                  setSelectedCountry(e.target.value);
                }}
                name="country"
                required
              >
                <option value="Shqiperi">Shqiperi</option>
                <option value="Kosove">Kosove</option>
                <option value="Macedoni">Macedoni</option>
              </select>
            </span>
            <span>
              <input name="city" type="text" placeholder="Qyteti" required />
              <input
                name="postalCode"
                type="number"
                placeholder="Kodi Postal"
                required
              />
            </span>
            <span>
              <input name="street" type="text" placeholder="Rruga" required />
              <input name="number" type="text" placeholder="Numri (Opsional)" />
            </span>
            <textarea
              name="message"
              cols="10"
              rows="4"
              placeholder="Mesazh (Opsionale)"
            ></textarea>
          </div>
          <button type="submit">PERFUNDO POROSINE</button>
        </motion.form>
      </>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
  margin: auto;
  max-width: 1500px;
  hr {
    height: 70vh;
    border: 1px solid #dfdfdf;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex: 3;
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      align-self: flex-start;
      border-bottom: 1px solid #e4e0db;
      width: 100%;
      img {
        height: 120px;
        width: auto;
        border-radius: 0px;
        object-fit: contain;
        background-color: white;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        h4 {
          text-transform: capitalize;
          font-size: 17px;
          font-weight: 300;
          color: gray;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          span {
            color: #353432;
            font-weight: 500;
          }
        }
        h3 {
          font-weight: 500;
          color: #575250;
        }
        h2 {
          margin-top: 10px;
          font-weight: 500;
          color: #8d7f7a;
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-top: 3em;
      hr {
        border: none;
        height: 1px;
        border-bottom: 1px solid #dad3d0;
      }
      span {
        display: flex;
        justify-content: space-between;
        h3 {
          font-weight: 400;
          color: #8d7f7a;
        }
        h2 {
          font-weight: 500;
          font-size: 19px;
          color: #9b8983;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          color: #575250;
        }
      }
    }
  }
  .right {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 2em;
    div {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    span {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    input,
    textarea,
    select {
      font-family: "Montserrat", sans-serif;
      outline: none;
      border: 1px solid #dfdfdf;
      font-size: 17px;
      border-radius: 7px;
      padding: 14px;
      background: none;
      color: gray;
      width: 100%;
    }
    label {
      font-weight: 500;
      font-size: 20px;
    }
    .shipping {
      background-color: var(--secondaryBackgroundColor);
      padding: 20px 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        h4 {
          font-weight: 300;
        }
      }
    }
    button {
      margin: auto;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      background: #5e524e;
      color: white;
      border: none;
      border-radius: 7px;
      padding: 10px 14px;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        box-shadow: 0 0 10px 2px #91827e;
      }
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 2em;
    hr {
      height: 1px;
    }
    .left {
      .total {
      }
    }
  }
`;

export default CheckoutTest;

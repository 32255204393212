import React from "react";
import styled from "styled-components";
import fenty from "../../assets/homeAssets/fentyLogo.jpg";
import ysl from "../../assets/homeAssets/yslLogo.png";
import ct from "../../assets/homeAssets/ctLogo.png";
import cerave from "../../assets/homeAssets/ceraveLogo.png";
import rarebeauty from "../../assets/homeAssets/rarebeautyLogo.png";

const Brands = () => {
  return (
    <Container>
      <img src={rarebeauty} alt="" />
      <img src={ysl} alt="" />
      <img src={ct} alt="" />
      <img src={fenty} alt="" />
    </Container>
  );
};
const Container = styled.div`
  padding: 5em 7% 8em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  img {
    height: 2vw;
    opacity: 0.4;
    filter: contrast(0.3);
  }
  :nth-child(2),
  :nth-child(1) {
    height: 2.7vw;
  }
  @media (max-width: 780px) {
    img {
      height: 3vw;
    }
    :nth-child(2) {
      height: 4vw;
    }
  }
  @media (max-width: 500px) {
    gap: 2em;
    img {
      height: 4vw;
    }
    :nth-child(2) {
      height: 5vw;
    }
  }
`;

export default Brands;

import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Products from "./routes/Products";
import ProductPage from "./routes/ProductPage";
import ContactPage from "./routes/ContactPage";
import AboutUs from "./routes/AboutUs";
import CartProvider from "./CartContext";
import NavbarTest from "./components/NavbarTest";
import { useEffect, useState } from "react";
import CheckoutPage from "./routes/CheckoutPage";
import Footer from "./components/Footer";
import SuccessPage from "./routes/SuccessPage";
import NotFoundPage from "./routes/NotFoundPage";
import ReactGA from "react-ga";
import Checkout2 from "./routes/Chekout2";
import CheckoutTest from "./routes/CheckoutTest";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

  ReactGA.initialize("G-4MF1LZLB3G ");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 740);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <CartProvider>
      <div className="App" style={{ paddingTop: isMobile ? 0 : "70px" }}>
        {isMobile ? <NavbarTest /> : <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/checkout" element={<CheckoutTest />} />
          <Route path="/success-page" element={<SuccessPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </CartProvider>
  );
}

export default App;
